var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" },
        },
        [
          _c("el-form-item", { attrs: { label: "版本号", prop: "edition" } }, [
            _c(
              "div",
              { staticClass: "parting-input-box" },
              [
                _c("el-input", {
                  attrs: {
                    disabled: !_vm.isAdd,
                    size: "small",
                    maxlength: "2",
                  },
                  on: { blur: _vm.setMajor },
                  model: {
                    value: _vm.major,
                    callback: function ($$v) {
                      _vm.major = $$v
                    },
                    expression: "major",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "parting-input-box parting-input-point-box" },
              [
                _c("el-input", {
                  attrs: {
                    disabled: !_vm.isAdd,
                    size: "small",
                    maxlength: "2",
                  },
                  on: { blur: _vm.setMinor },
                  model: {
                    value: _vm.minor,
                    callback: function ($$v) {
                      _vm.minor = $$v
                    },
                    expression: "minor",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "parting-input-box parting-input-point-box" },
              [
                _c("el-input", {
                  attrs: {
                    disabled: !_vm.isAdd,
                    size: "small",
                    maxlength: "4",
                  },
                  on: { blur: _vm.setPatch },
                  model: {
                    value: _vm.patch,
                    callback: function ($$v) {
                      _vm.patch = $$v
                    },
                    expression: "patch",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "固件说明", prop: "description" } },
            [
              _c("el-input", {
                attrs: { size: "small" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "固件类别", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: !_vm.isAdd,
                    size: "small",
                    clearable: "",
                    placeholder: "请选择固件类别",
                  },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.typeList, function (type) {
                  return _c(
                    "el-tooltip",
                    {
                      key: type.value,
                      staticClass: "item",
                      attrs: {
                        in: "",
                        effect: "dark",
                        content: type.starts
                          ? `${type.label}的固件包 请使用以 “${type.starts}” 开头的文件名`
                          : "无说明",
                        placement: "right",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: type.label, value: type.value },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.labelName, prop: "file" } },
            [
              _vm.data == null
                ? [
                    _c(
                      "el-upload",
                      {
                        ref: "upload",
                        staticClass: "upload-demo",
                        attrs: {
                          action: "/api/devices/wrap/uploadFile",
                          data: _vm.form,
                          headers: _vm.headers,
                          accept: ".bin",
                          "on-error": _vm.handleError,
                          "on-change": _vm.handleChange,
                          "on-success": _vm.handleSuccess,
                          "file-list": _vm.fileList,
                          multiple: false,
                          "auto-upload": false,
                        },
                        model: {
                          value: _vm.form.file,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "file", $$v)
                          },
                          expression: "form.file",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "trigger",
                              size: "small",
                              type: "primary",
                            },
                            slot: "trigger",
                          },
                          [_vm._v("选取文件")]
                        ),
                        _c("div", [
                          _vm._v("只能上传 bin 文件，且不超过 128 K"),
                        ]),
                      ],
                      1
                    ),
                  ]
                : [_vm._v(" " + _vm._s(_vm.data.deviceName) + " ")],
            ],
            2
          ),
        ],
        1
      ),
      _c("dialog-footer", {
        on: { reset: _vm.onCancel, submit: _vm.onSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }