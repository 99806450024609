export default {
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '一周内',
          onClick(picker) {
            const date = new Date();
            date.setDate(date.getDate() - 7);
            picker.$emit('pick', date);
          }
        }, {
          text: '一个月内',
          onClick(picker) {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            picker.$emit('pick', date);
          }
        }, {
          text: '一年内',
          onClick(picker) {
            const date = new Date();
            date.setFullYear(date.getFullYear() - 1);
            picker.$emit('pick', date);
          }
        }]
      },
      timeRange: [],
      defaultTime: []
    }
  },
  created () {
    const now = new Date();
    this.timeRange = [new Date(new Date().setDate(now.getDate() - 7)), now]; // 默认时间范围一周
    const time = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
    this.defaultTime = [time, time];
  }
};