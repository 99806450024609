var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "test" }, [
    _c("div", { staticClass: "qrcode" }, [
      _c("div", [_vm._v("项目编号：" + _vm._s(_vm.HC_No))]),
      _c("canvas", { ref: "qrcodeCanvas" }),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini", round: "" },
              on: { click: _vm.onDownload },
            },
            [_vm._v("下载二维码")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }