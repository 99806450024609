var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs-data-box tabs-data-box2" },
    [
      _c(
        "div",
        { staticClass: "right-btns m-b-20" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini", icon: "el-icon-upload" },
              on: {
                click: function ($event) {
                  return _vm.onShowDialog("upConfigLog")
                },
              },
            },
            [_vm._v("上传配置文件")]
          ),
          _c(
            "el-button",
            {
              staticClass: "search-box-btn",
              attrs: { type: "primary", size: "mini", icon: "el-icon-search" },
              on: { click: _vm.onSearch },
            },
            [_vm._v("查询")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                height: _vm.tableHeight,
                "header-cell-style": {
                  background: "#F7F7F7",
                  color: "#2d2d2d",
                  "text-align": "center",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "上传时间", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "fileName", label: "文件名", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { prop: "description", label: "文件描述" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(scope.row.description),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "adminMobile",
                  label: "操作",
                  width: _vm.actionWidth,
                  align: "center",
                  fixed: "right",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              icon: "el-icon-info",
                              "icon-color": "#FF0000",
                              "confirm-button-type": "danger",
                              title: "删除操作不可恢复，您确认要删除么？",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.onDel(scope.row.id)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                size: "mini",
                                icon: "el-icon-delete",
                              },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticClass: "text-right m-t-10",
            attrs: {
              background: "",
              layout: "sizes, prev, pager, next, jumper",
              "page-sizes": [20, 50, 100],
              "page-size": _vm.pageData.size,
              total: _vm.pageData.total,
              "current-page": _vm.pageData.current,
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm.dialogVisible.upConfigLog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "上传测试记录",
                "destroy-on-close": "",
                "close-on-press-escape": false,
                "show-close": "",
                "close-on-click-modal": true,
                visible: _vm.dialogVisible.upConfigLog,
                width: "550px",
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseDialog("upConfigLog")
                },
              },
            },
            [
              _c("up-config-log", {
                ref: "upConfigLog",
                attrs: { height: _vm.height },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }