import { getRackWarningSummary, getRackAlarmSummary, getRackCriticalAlarmSummary, } from "@/utils/address-configs";
import { addrValToObject } from "@/utils/device";

const { calcHexFromUnsignedDec, calcUnsignedDecFromHex, binaryAppendZero, decChangeScale, hexAppendZero } = $numberMatrixing;

export const parseAseembleData = (str) => {
  const val = addrValToObject(str);
  const object = Object.assign({}, val);

  const array = [];
  if (object.rackOnlineStatus) {
    object.rackOnlineStatus = binaryAppendZero(decChangeScale(object.rackOnlineStatus, 2), 16);
    object.rackOnlineStatus = String.reverse(object.rackOnlineStatus);
    
    let index = 0;
    for (; index < object.numberOfTotalRacks; index++) {
      array.push(object.rackOnlineStatus[index] === "0" ? "离线" : "在线");
    }
  }
  object.rackOnlineStatusArray = array;

  // Rack 故障汇总
  const displayFaultFlg = 1 << 8;
  const _485_3FaultFlg = 1 << 9;
  const rackFaultSummary = Number(object.rackFaultSummary);
  const rackFaultSummaryArr = [];
  if (rackFaultSummary === 0) {
    rackFaultSummaryArr.push("正常");
  } else if (rackFaultSummary === 1) {
    rackFaultSummaryArr.push("故障");
  } else {
    if ((rackFaultSummary & displayFaultFlg) === displayFaultFlg) {
      rackFaultSummaryArr.push("显示屏通讯故障");
    }
    if ((rackFaultSummary & _485_3FaultFlg) === _485_3FaultFlg) {
      rackFaultSummaryArr.push("485-3 通讯故障");
    }
  }
  object.rackFaultSummary = rackFaultSummaryArr.join();

  object.systemCharge = Number(object.systemCharge);
  object.rackWarningSummary = getRackWarningSummary(object.rackWarningSummary);
  object.rackAlarmSummary = getRackAlarmSummary(object.rackAlarmSummary);
  object.rackCriticalAlarmSummary = getRackCriticalAlarmSummary(object.rackCriticalAlarmSummary);
  object.systemEnableDiscargeEnergy = object.discargeEnergy;

  if (object.humidity > 1000) {
    object.humidity = null;
  }

  object.accumulationHarge = calcHexFromUnsignedDec(object.accumulationHargeMSB) + calcHexFromUnsignedDec(object.accumulationHargeLSB);
  object.accumulationHarge = calcUnsignedDecFromHex(object.accumulationHarge);
  object.accumulationDischargeEnergy = calcHexFromUnsignedDec(object.accumulationDischargeEnergyMSB) + calcHexFromUnsignedDec(object.accumulationDischargeEnergyLSB);
  object.accumulationDischargeEnergy = calcUnsignedDecFromHex( object.accumulationDischargeEnergy);

  if (object.onOffState) {
    object.onOffState = calcHexFromUnsignedDec(object.onOffState).toUpperCase();
  } else {
    object.onOffState = "0000";
  }

  switch (Number(object.fireProtectionStatus)) {
    case 0:
      delete object.fireProtectionStatus; // 因为前面的 Number() 处理有隐式数据类型转换，所以这里还原回去
      break;
    case 0x9:
      object.fireProtectionStatus = "正常";
      break;
    case 0xef:
    case 0xff:
      object.fireProtectionStatus = "故障";
      break;
    case 0x85:
      object.fireProtectionStatus = "预警";
      break;
    case 0x80:
    case 0x0a:
      object.fireProtectionStatus = "报警";
      break;
  }

  switch (Number(object.waterloggingStatus)) {
    case 0x00:
      object.waterloggingStatus = "正常";
      break;
    case 0xff:
      object.waterloggingStatus = "故障";
      break;
    case 0x01:
      object.waterloggingStatus = "报警";
      break;
  }

  if ((object.totalEnergySavingMSB || object.totalEnergySavingMSB === 0) && (object.totalEnergySavingLSB || object.totalEnergySavingLSB === 0)) {
    let totalEnergySaving = "0x" + hexAppendZero(calcHexFromUnsignedDec(object.totalEnergySavingMSB))
                          + hexAppendZero(calcHexFromUnsignedDec(object.totalEnergySavingLSB));
    totalEnergySaving = Number(totalEnergySaving);

    // if (totalEnergySaving === 0) { // [20240124] 这里是应付客户
    //   const dt = new Date();
    //   let day1Time = new Date(dt.getFullYear(),0,0).getTime();
    //   let dtTime = dt.getTime();
    //   let days = Math.floor((dtTime - day1Time)/(1000 * 60 * 60 * 24));
    //   totalEnergySaving = Number(object.dailyEnergySaving) + days + dt.getMonth() * Math.random();
    //   totalEnergySaving = Math.ceil(totalEnergySaving);
    // }

    object.totalEnergySaving = totalEnergySaving;
  }

  const airConditioningSum = Number(object.airConditioningSum);
  const airconStatusArr = [];
  let { airconditionStatus1, airconditionStatus2 } = object;
  if ((airconditionStatus1 || airconditionStatus1 === 0) && (airconditionStatus2 || airconditionStatus2 === 0)) {
    airconditionStatus1 = calcHexFromUnsignedDec(airconditionStatus1);
    airconditionStatus1 = String.reverse(airconditionStatus1);
    airconditionStatus2 = calcHexFromUnsignedDec(airconditionStatus2);
    airconditionStatus2 = String.reverse(airconditionStatus2);

    let airconditionStatus = airconditionStatus1 + airconditionStatus2;
    airconditionStatus = airconditionStatus.toUpperCase();

    for (let i = 0; i < airConditioningSum; i++) {
      let val = airconditionStatus.charAt(i);

      switch (val) {
        case "0":
          airconStatusArr.push("停机");
          break;
        case "1":
          airconStatusArr.push("制冷");
          break;
        case "2":
          airconStatusArr.push("制热");
          break;
        case "3":
          airconStatusArr.push("通风");
          break;
        case "F":
          airconStatusArr.push("故障");
          break;
      }
    }

  // [20240117] 总控时，暂时把电流按充放电状态（1：放电，2：充电）来设置：充电为正，放电为负
  if (object.systemCharge === 2) {
    if (object.systemTotalCurrent < 0) {
      object.systemTotalCurrent = 0 - object.systemTotalCurrent;
    }
  } else if (object.systemCharge === 1) {
    if (object.systemTotalCurrent > 0) {
      object.systemTotalCurrent = 0 - object.systemTotalCurrent;
    }
  }

    object.airconStatusArr = airconStatusArr;
  }

  // 总[尖峰平谷]段[充放]电量
  const totalTopChargingEnergy = calcHexFromUnsignedDec(object.totalTopChargingEnergyMSB) + calcHexFromUnsignedDec(object.totalTopChargingEnergyLSB);
  object.totalTopChargingEnergy = calcUnsignedDecFromHex(totalTopChargingEnergy);

  const totalPeakChargingEnergy = calcHexFromUnsignedDec(object.totalPeakChargingEnergyMSB) + calcHexFromUnsignedDec(object.totalPeakChargingEnergyLSB);
  object.totalPeakChargingEnergy = calcUnsignedDecFromHex(totalPeakChargingEnergy);

  const totalNormalChargingEnergy = calcHexFromUnsignedDec(object.totalNormalChargingEnergyMSB) + calcHexFromUnsignedDec(object.totalNormalChargingEnergyLSB);
  object.totalNormalChargingEnergy = calcUnsignedDecFromHex(totalNormalChargingEnergy);

  const totalValleyChargingEnergy = calcHexFromUnsignedDec(object.totallValleyChargingEnergyMSB) + calcHexFromUnsignedDec(object.totallValleyChargingEnergyLSB);
  object.totalValleyChargingEnergy = calcUnsignedDecFromHex(totalValleyChargingEnergy);

  const totalTopDischargingEnergy = calcHexFromUnsignedDec (object.totalTopDischargingEnergyMSB) + calcHexFromUnsignedDec (object.totalTopDischargingEnergyLSB);
  object.totalTopDischargingEnergy = calcUnsignedDecFromHex(totalTopDischargingEnergy);

  const totalPeakDischargingEnergy = calcHexFromUnsignedDec (object.totalPeakDischargingEnergyMSB) + calcHexFromUnsignedDec (object.totalPeakDischargingEnergyLSB);
  object.totalPeakDischargingEnergy = calcUnsignedDecFromHex(totalPeakDischargingEnergy);

  const totalNormalDischargingEnergy = calcHexFromUnsignedDec (object.totalNormalDischargingEnergyMSB) + calcHexFromUnsignedDec (object.totalNormalDischargingEnergyLSB);
  object.totalNormalDischargingEnergy = calcUnsignedDecFromHex(totalNormalDischargingEnergy);

  const totalValleyDischargingEnergy = calcHexFromUnsignedDec (object.totallValleyDischargingEnergyMSB) + calcHexFromUnsignedDec (object.totallValleyDischargingEnergyLSB);
  object.totalValleyDischargingEnergy = calcUnsignedDecFromHex(totalValleyDischargingEnergy);

  return object;
}

/**
 * 
 * @param {*} start 起始地址值
 * @param {*} size 请求长度
 * @param {*} valeus 返回值
 */
export const parseModBus = (start, size, valeus) => {
  // 1. 先通过地址找到对应的变量
  // 2. 组合返回值

  const output = {};

  for (let i = 0; i < size; i++) {
    const addr = calcHexFromUnsignedDec(start + i);
    const obj = getObjByAddress(addr);
    if (!obj) return;

    const { type, size, name } = obj;

    const method = type ? "calcUnsignedDecFromHex": "calcSignedDecFromHex";  // true: unsigned , false signed

    let begin = i * 4;
    if (size === 32) {
      i++;
    }
    let end = (i + 1) * 4;

    const value = values.slice(begin, end);
    value = $numberMatrixing[method](value);

    output[name] = value;
  }

  return output;
}

export default {
  parseAseembleData,
  parseModBus
}