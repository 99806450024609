var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-col",
        { attrs: { span: _vm.span } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公司分类" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    clearable: "",
                    placeholder: "请选择公司分类",
                    size: "mini",
                  },
                  on: { change: _vm.onSelect },
                  model: {
                    value: _vm.dataObj.tenantId,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataObj, "tenantId", $$v)
                    },
                    expression: "dataObj.tenantId",
                  },
                },
                _vm._l(_vm.roleGroups, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: _vm.span } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公司名称" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: _vm.companyDisabled,
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择一个公司",
                    size: "mini",
                  },
                  on: { change: _vm.onChange },
                  model: {
                    value: _vm.dataObj.businessId,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataObj, "businessId", $$v)
                    },
                    expression: "dataObj.businessId",
                  },
                },
                _vm._l(_vm.companyList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.companyName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }