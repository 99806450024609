<template>
    <div class="tabs-data-box tabs-data-box2">
    <el-form>
      <el-row>
        <el-col span="24">
          <div class="right-btns">
            <el-button type="primary" size="mini" @click="onCreateData">创建数据</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <el-table v-loading="loading" :data="list" style="width: 100%" :height="tableHeight"
      :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
      <el-table-column label="项目编号" prop="hcNo" align="center"></el-table-column>
      <el-table-column label="项目名称" prop="hcProject" align="center"></el-table-column>
      <el-table-column label="生产日期" prop="hcCreateTime" align="center"></el-table-column>
      <el-table-column label="操作" width="220px" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onDetailData(scope.row)">查看数据</el-button>
          <el-button type="primary" size="mini" @click="onShowQrCode(scope.row)">显示二维码</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
      :page-sizes="[20, 50, 100]" @size-change="onSizeChange"
      :page-size="pageData.size" :total="pageData.total" :current-page="pageData.current" @current-change="pageChange" />
    <el-dialog title="二维码" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" v-if="dialogVisible.qrcode" :visible="dialogVisible.qrcode"
      @close="onCloseDialog('qrcode', false)"  width="640px">
      <qrcode ref="qrcode" :data="data" mode="dialog"></qrcode>
    </el-dialog>
    <el-dialog :title="title" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" v-if="dialogVisible.edit" :visible="dialogVisible.edit"
      @close="onCloseDialog('edit', false)" width="600px">
      <edit ref="edit" :data="data" mode="dialog"  @close-edit-modal="onCloseEdit"></edit>
    </el-dialog>
  </div>
</template>
<script>
import edit from "./edit";
import qrcode from "./qrcode";

import qrCodeApi from "@/api/qrcode";

let pageData = {
  current: 1,
  size: 20,
  total: 0
};
const protoPageData = JSON.stringify(pageData);

export default {
  components: {
    edit,
    qrcode
  },
  data () {
    return {
      loading: false,
      list: [],
      title: "",
      tableHeight: 0,
      data: {},
      pageData: pageData,
      dialogVisible: {
        qrcode: false,
        edit: false
      }
    }
  },
  created () {
    this.tableHeight = document.body.scrollHeight - 205;
  },
  mounted () {
    this.$route.meta.deactivated = () => {
      this.pageData = pageData = JSON.parse(protoPageData);
    }
    this.getDatas();
  },
  methods: {
    onSizeChange (e) {
      pageData.size = e;
      pageData.current = 1;
      this.getDatas();
    },
    pageChange(e) {
      pageData.current = e;
      this.getDatas();
    },
    async getDatas () {

      try {
        this.loading = true;

        const pamras = {
          page: pageData.current,
          size: pageData.size
        };

        const res = await qrCodeApi.list(pamras);

        // 这里是为了兼容之前没有修改过的内容
        const list = res.data.map(obj => {
          let value = null;
          if (obj.value) {
            value = JSON.parse(obj.value);
            const keys = Object.keys(value);
            for (const key of keys) {
              let newKey = key.replace("HC_", "hc");
              newKey = newKey.replace("PCBA", "Pcba");
              value[newKey] = value[key];
              delete value[key];
            }
            value.id = obj.id;
            value.timeStamp = obj.timeStamp;
          } else {
            value = obj;
          }

          return value;
        });

        this.list = list;
        pageData = this.pageData = res.paging;
      } catch (err) {
        console.log(err);
        this.list = [];
      } finally {
        this.loading = false;
      }
    },
    onCreateData () {
      this.title = "创建数据";
      this.data = null;
      this.dialogVisible.edit = true;
    },
    onDetailData (data) {
      this.title = "查看数据";
      this.data = data;
      this.dialogVisible.edit = true;
    },
    onShowQrCode (data) {
      this.data = data;
      this.dialogVisible.qrcode = true;
    },
    onCloseEdit (data) {
      this.onCloseDialog("edit", true);
      this.onShowQrCode(data);
    },
    async onCloseDialog (refName, ignore) {
      // 通过 emit 发送的关闭请求一律通过
      // 如果点击 close 图标则一律直接关闭
      if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
        // 设置 visable 后,会重新触发 close 所以需要先 remove edit
        delete this.$refs[refName];
        this.dialogVisible[refName] = false;
      }
      if (ignore) {
        this.getDatas();
      }
    },
  }
}
</script>