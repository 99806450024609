<template>
  <div class="tabs-data-box tabs-data-box2">
    <div v-loading="loading">
      <el-table ref="table" :data="alarms" :height="height ? height: undefined" style="width: 100%"
              :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
                  <el-table-column label="设备编号" prop="productId" width="100" align="center"></el-table-column>
                  <el-table-column label="上报时间" prop="createTime" width="160" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.updateTime || scope.row.createTime }}
                    </template>
                  </el-table-column>
                  <el-table-column label="设备告警" prop="warningData">
                    <template slot-scope="scope">
                      <div v-if="scope.row.warning == null"></div>
                      <div v-else-if="scope.row.warning.length > 0">
                        <span v-if="scope.row.warning[0]">总压过高一级告警 </span>
                        <span v-if="scope.row.warning[1]">总压过高二级告警 </span>
                        <span v-if="scope.row.warning[2]">总压过高三级告警 </span>
                        <span v-if="scope.row.warning[3]">总压过低一级告警 </span>
                        <span v-if="scope.row.warning[4]">总压过低二级告警 </span>
                        <span v-if="scope.row.warning[5]">总压过低三级告警 </span>
                        <span v-if="scope.row.warning[6]">单体过高一级告警 </span>
                        <span v-if="scope.row.warning[7]">单体过高二级告警 </span>
                        <span v-if="scope.row.warning[8]">单体过高三级告警 </span>
                        <span v-if="scope.row.warning[9]">单体过低一级告警 </span>
                        <span v-if="scope.row.warning[10]">单体过低二级告警 </span>
                        <span v-if="scope.row.warning[11]">单体过低三级告警 </span>
                        <span v-if="scope.row.warning[12]">高段压差过高一级告警 </span>
                        <span v-if="scope.row.warning[13]">高段压差过高二级告警 </span>
                        <span v-if="scope.row.warning[14]">高段压差过高三级告警 </span>
                        <span v-if="scope.row.warning[15]">中段压差过高一级告警 </span>
                        <span v-if="scope.row.warning[16]">中段压差过高二级告警 </span>
                        <span v-if="scope.row.warning[17]">中段压差过高三级告警 </span>
                        <span v-if="scope.row.warning[18]">低段压差过高一级告警 </span>
                        <span v-if="scope.row.warning[19]">低段压差过高二级告警 </span>
                        <span v-if="scope.row.warning[20]">低段压差过高三级告警 </span>
                        <span v-if="scope.row.warning[21]">辅助压差过高一级告警 </span>
                        <span v-if="scope.row.warning[22]">辅助压差过高二级告警 </span>
                        <span v-if="scope.row.warning[23]">辅助压差过高三级告警 </span>
                        <span v-if="scope.row.warning[24]">充电电流过高一级告警 </span>
                        <span v-if="scope.row.warning[25]">充电电流过高二级告警 </span>
                        <span v-if="scope.row.warning[26]">充电电流过高三级告警 </span>
                        <span v-if="scope.row.warning[27]">放电电流过高一级告警 </span>
                        <span v-if="scope.row.warning[28]">放电电流过高二级告警 </span>
                        <span v-if="scope.row.warning[29]">放电电流过高三级告警 </span>
                        <span v-if="scope.row.warning[30]">反充电流过高一级告警 </span>
                        <span v-if="scope.row.warning[31]">反充电流过高二级告警 </span>
                        <span v-if="scope.row.warning[32]">反充电流过高三级告警 </span>
                        <span v-if="scope.row.warning[33]">SOC值过低一级告警 </span>
                        <span v-if="scope.row.warning[34]">SOC值过低二级告警 </span>
                        <span v-if="scope.row.warning[35]">SOC值过低三级告警 </span>
                        <span v-if="scope.row.warning[36]">SOH值过低一级告警 </span>
                        <span v-if="scope.row.warning[37]">SOH值过低二级告警 </span>
                        <span v-if="scope.row.warning[38]">SOH值过低三级告警 </span>
                        <span v-if="scope.row.warning[39]">继电器温度过高一级告警 </span>
                        <span v-if="scope.row.warning[40]">继电器温度过高二级告警 </span>
                        <span v-if="scope.row.warning[41]">继电器温度过高三级告警 </span>
                        <span v-if="scope.row.warning[42]">充电温度过高一级告警 </span>
                        <span v-if="scope.row.warning[43]">充电温度过高二级告警 </span>
                        <span v-if="scope.row.warning[44]">充电温度过高三级告警 </span>
                        <span v-if="scope.row.warning[45]">放电温度过高一级告警 </span>
                        <span v-if="scope.row.warning[46]">放电温度过高二级告警 </span>
                        <span v-if="scope.row.warning[47]">放电温度过高三级告警 </span>
                        <span v-if="scope.row.warning[48]">充电温度过低一级告警 </span>
                        <span v-if="scope.row.warning[49]">充电温度过低二级告警 </span>
                        <span v-if="scope.row.warning[50]">充电温度过低三级告警 </span>
                        <span v-if="scope.row.warning[51]">放电温度过低一级告警 </span>
                        <span v-if="scope.row.warning[52]">放电温度过低二级告警 </span>
                        <span v-if="scope.row.warning[53]">放电温度过低三级告警 </span>
                        <span v-if="scope.row.warning[54]">温差过大一级告警 </span>
                        <span v-if="scope.row.warning[55]">温差过大二级告警 </span>
                        <span v-if="scope.row.warning[56]">温差过大三级告警 </span>
                        <span v-if="scope.row.warning[57]">均衡温度过高一级告警 </span>
                        <span v-if="scope.row.warning[58]">均衡温度过高二级告警 </span>
                        <span v-if="scope.row.warning[59]">均衡温度过高三级告警 </span>
                        <span v-if="scope.row.warning[60]">绝缘电阻过低一级告警 </span>
                        <span v-if="scope.row.warning[61]">绝缘电阻过低二级告警 </span>
                        <span v-if="scope.row.warning[62]">绝缘电阻过低三级告警 </span>
                        <span v-if="scope.row.warning[63]">总压采样异常一级告警 </span>
                        <span v-if="scope.row.warning[64]">总压采样异常二级告警 </span>
                        <span v-if="scope.row.warning[65]">总压采样异常三级告警 </span>
                        <span v-if="scope.row.warning[66]">静置温度过高一级告警 </span>
                        <span v-if="scope.row.warning[67]">静置温度过高二级告警 </span>
                        <span v-if="scope.row.warning[68]">静置温度过高三级告警 </span>
                        <span v-if="scope.row.warning[69]">静置温度过低一级告警 </span>
                        <span v-if="scope.row.warning[70]">静置温度过低二级告警 </span>
                        <span v-if="scope.row.warning[71]">静置温度过低三级告警 </span>
                        <span v-if="scope.row.warning[72]">主正接触器无法吸合 </span>
                        <span v-if="scope.row.warning[73]">主正接触器粘连故障 </span>
                        <span v-if="scope.row.warning[74]">主负接触器无法吸合 </span>
                        <span v-if="scope.row.warning[75]">主负接触器粘连故障 </span>
                        <span v-if="scope.row.warning[76]">充电接触器无法吸合 </span>
                        <span v-if="scope.row.warning[77]">充电接触器粘连故障 </span>
                        <span v-if="scope.row.warning[78]">预热接触器无法吸合 </span>
                        <span v-if="scope.row.warning[79]">预热接触器粘连故障 </span>
                        <span v-if="scope.row.warning[80]">风扇接触器无法吸合 </span>
                        <span v-if="scope.row.warning[81]">风扇接触器粘连故障 </span>
                        <span v-if="scope.row.warning[82]">预充接触器无法吸合 </span>
                        <span v-if="scope.row.warning[83]">预充接触器粘连故障 </span>
                      </div>
                    <div v-else>设备无告警</div>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="自检告警" prop="selfTest"></el-table-column> -->
      </el-table>
      <div class="right-btns" style="margin-top:15px;">
        <el-button icon="el-icon-refresh" type="primary" size="small" @click="getDatas">刷新数据</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {  getDevicesWarngingList } from '@/api/device';

// import { parseWarningData } from "@/utils/device";

export default {
  computed: {
    ...mapGetters(['user']),
  },
  data () {
    return {
      loading: false,
      height: 0,
      alarms: []
    }
  },
  created () {
    this.height = document.body.scrollHeight - 160;
    this.getDatas();
  },
  methods: {
    async getDatas() {
      try {
        this.loading = true;
        const businessId = this.user.tenantId === 0 ? undefined : this.user.businessId;
        let res = await getDevicesWarngingList(businessId);

        const wObj = res.map(obj => {

          let { warning } = obj;

          if (warning.length < 84) {
            for (let i = warning.length; i < 84; i++) {
              warning = "0" + warning;
            }
          }

          const { warningData, count } = $utils.parseWarningData(warning);

          obj.warning = count > 0 ? warningData: [];

          return obj;
        });

        this.alarms = wObj;
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>