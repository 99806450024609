<template>
  <div v-loading="loading">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="文件描述" prop="description">
        <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 5}" v-model="form.description" clearable size="mini" placeholder="请输入配置文件的描述" />
      </el-form-item>
      <!-- <el-form-item label="设备类型" prop="type">
        <el-select v-model="form.type" clearable size="mini" style="width: 100%;">
          <el-option v-for="type in btnType" :key="type.code" :label="type.name" :value="type.code"></el-option>
          </el-select>
        </el-form-item> -->
    </el-form>
    <el-upload
      style="margin-top: 5px;"
      ref="upload"
      action=""
      :headers="headers"
      :on-change="handleChange"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      accept=".ini"
      :data="form"
      :file-list="fileList"
      :auto-upload="false">
      <el-button slot="trigger" icon="el-icon-document-checked" size="mini" type="primary">选取文件</el-button>
      <div class="m-t-10">
        <el-alert show-icon type="warning" effect="dark" :closable="false" title="只能上传 ini 配置文件"></el-alert>
        <el-alert show-icon style="margin-top:5px;" type="warning" effect="dark" :closable="false" title="上传前，请将文件名修改为 年月日（如：20240101）的格式，避免出现重复"></el-alert>
        <el-alert show-icon style="margin-top:5px;" type="warning" effect="dark" :closable="false" title="如果文件选择错误，请删除已上传文件后，再重新上传一次！"></el-alert>
      </div>
    </el-upload>
    <div class="m-t-10 right-btns">
      <el-button icon="el-icon-upload" style="margin-left: 10px;" size="mini" type="primary" @click="submitUpload">上传</el-button>
    </div>
  </div>
</template>
<script>
import { getToken } from '@/utils/auth';
import { uploadTestLog } from "@/api/device";

export default {
  props: {
    deviceInfo: undefined,
  },
  data() {
    return {
      fileList: [],
      loading: false
    };
  },
  computed: {
    headers: function () {
      const token = getToken();
      return { 
        Authorization: token
      };
    }
  },
  data () {
    return {
      form: {
        description: "",
        code: "000000",
        type: 0
      },
      rules: {
        description:  [
          { required: true, message: '请输入配置文件的描述', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    submitUpload() {
      this.$refs.form.validate(async (valid) => {

        if (!valid) {
          return;
        }

        if (!this.fileList) {
          this.$message.warning('请选取文件后再上传');
          return;
        }

        const dt = $utils.formatTime(new Date(), "YYYYMMDDHHmmss");
        const fileName = `${dt}.ini`;
        const formData = new FormData()
        // 你可以使用FormData.append来添加键/值对到表单里面；
        this.fileList.forEach((file) => {
          formData.append('file', file.raw)
        });

        formData.append("code", this.form.code);
        formData.append("description", this.form.description);
        formData.append("type", this.form.type);

        try {
          this.loading = true;
          await uploadTestLog(formData);
          this.$message.success("文件上传成功");
        } catch (e) {
          this.$message.error(e.response.data.message || "文件上传失败");
        } finally {
          this.loading = false;
        }
      });
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePreview(file, fileList) {
      this.fileList = fileList;
    }
  }
}
</script>