<template>
  <div @click="hideCheckBoxGroup" class="tabs-data-box tabs-data-box2">
    <el-form label-width="80" style="padding-left: 20px;">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="查询时间">
            <el-date-picker
              size="mini"
              v-model="timeRange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime"
              @change="getDatas"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="只看最大最小值">
            <el-checkbox v-model="lookMaxMin" @change="onSearch"></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="选取设备">
            <el-select style="width:calc(100% - 90px);" v-model="selectedCodes" size="mini" collapse-tags multiple placeholder="请选择设备" filterable clearable @change="onSearch">
              <el-option v-for="device in deviceList" :key="device.code" :label="`${device.information}(${device.code})`" :value="device.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <div class="right-btns">
            <div style="margin-top:4px; display: inline-block;" @click.stop="">
              <el-button v-if="!useImdependenceItem" type="primary" :disabled="loading" :icon="checkBoxIcon" size="mini" @click="showHideCheckBoxGroup">选择要显示的项目</el-button>
              <div class="columns-select-box" v-if="isShowCheckBoxGroup">
                <el-checkbox-group v-model="checkedColumns" @change="handleCheckedColumnsChange">
                  <div class="check-box-item-box" v-for="(column, index) in columns" :key="index">
                    <el-checkbox :label="column.value">{{column.label}}</el-checkbox>
                  </div>
                </el-checkbox-group>
                <div class="checkbox-btns-box">
                  <div>
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                  </div>
                  <div class="right-btns">
                    <el-button size="small" @click="toDefaultCheckBox">默认值</el-button>
                    <el-button size="small" @click="hideCheckBoxGroup">取消</el-button>
                    <el-button type="primary" size="small" @click="onSelectShowColumns">确定</el-button>
                  </div>
                </div>
              </div>
            </div>
            <el-button icon="el-icon-search" :disabled="loading" class="search-box-btn" type="primary" size="mini" @click="onSearch">查询</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div>
      <div class="data-table" v-loading="loading" style="margin-left: 10px;">
        <div>
          <el-table ref="table" :data="list" :height="height ? height: undefined" style="width: 100%" @selection-change="handleSelectionChange"
              :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
            <el-table-column type="index" label="序号" width="50" align="center" fixed="left">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color': ''">{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="上报时间" prop="createTime" width="160" align="center" fixed="left">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color': ''">{{ scope.row.updateTime || scope.row.createTime }}</span>
              </template>
            </el-table-column>
            <el-table-column label="设备编号" prop="code" width="80" align="center" fixed="left">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color': ''">{{ scope.row.code }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('onOffState')" label="开关机状态" min-width="220" prop="onOffState" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'"
                  v-if="utils.checkObjectIsNotEmpty(scope.row.onOffState) && scope.row.onOffState !=='0000'">{{ runStatusMap[scope.row.onOffState].label }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemState')" label="EMS系统状态" min-width="220" prop="systemState" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'"
                  v-if="utils.checkNumberIsNotEmpty(scope.row.systemState)">{{ systemStatusMap[scope.row.systemState].label }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('temperature')" label="系统温度" min-width="150" prop="temperature" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'"
                  v-if="utils.checkObjectIsNotEmpty(scope.row.temperature)">{{ (Number(scope.row.temperature) / 10).toFixed(2) }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('humidity')" label="系统湿度" min-width="150" prop="humidity" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'"
                  v-if="utils.checkObjectIsNotEmpty(scope.row.humidity)">{{ (Number(scope.row.humidity) / 10).toFixed(2) }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('accumulationDischarge')" sortable :sort-method="(a, b) => sortMethod(a.accumulationDischarge, b.accumulationDischarge)"
              label="累计放电电量(EMS)(KWH)" min-width="220" prop="accumulationDischarge" align="center">
              <template slot-scope="scope">
                <span v-if="lookMaxMin">
                  <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.accumulationDischarge)">
                      {{ scope.row.accumulationDischarge }}
                  </span>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
                </span>
                <span v-else>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.accumulationDischargeEnergy)">
                      {{ Number(scope.row.accumulationDischargeEnergy).toFixed(2) }}
                  </span>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('accumulationHarge')" sortable :sort-method="(a, b) => sortMethod(a.accumulationHarge, b.accumulationHarge)"
              label="累计充电电量(EMS)(KWH)" min-width="220" prop="accumulationHarge" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.accumulationHarge)">
                  {{ lookMaxMin ? scope.row.accumulationHarge : Number(scope.row.accumulationHarge).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('airConditioningSum')" label="空调数量" min-width="120" prop="airConditioningSum" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.airConditioningSum)">{{ scope.row.airConditioningSum }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('airConditioningStatus')" label="空调状态" min-width="300" prop="airConditioningStatus" align="center">
              <template slot-scope="scope">
                <span v-if="lookMaxMin">
                  <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.airConditioningStatus)">
                    {{ scope.row.airConditioningStatus }}
                  </span>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
                </span>
                <span v-else>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.airconStatusArr)">
                    {{ scope.row.airconStatusArr.join() }}
                  </span>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('alternatingCurrentActivePower')" sortable :sort-method="(a, b) => sortMethod(a.alternatingCurrentActivePower, b.alternatingCurrentActivePower)"
              label="交流有功功率(EMS)(KW)" min-width="220" prop="alternatingCurrentActivePower" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.alternatingCurrentActivePower)">{{ (Number(scope.row.alternatingCurrentActivePower) / 10).toFixed(2) }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('alternatingCurrentReactivePower')"  sortable :sort-method="(a, b) => sortMethod(a.alternatingCurrentReactivePower, b.alternatingCurrentReactivePower)"
              label="交流无功功率(EMS)(KW)" min-width="220" prop="alternatingCurrentReactivePower" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.alternatingCurrentReactivePower)">{{ Number(scope.row.alternatingCurrentReactivePower).toFixed(2) }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('bmsHeartBeat')"  sortable :sort-method="(a, b) => sortMethod(a.bmsHeartBeat, b.bmsHeartBeat)"
              label="BMS心跳" min-width="120" prop="bmsHeartBeat" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.bmsHeartBeat)">{{ scope.row.bmsHeartBeat }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('dailyChargingEnergy')"  sortable :sort-method="(a, b) => sortMethod(a.dailyChargingEnergy, b.dailyChargingEnergy)"
              label="日充电量(EMS)(KWH)" min-width="200" prop="dailyChargingEnergy" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.dailyChargingEnergy)">{{ Number(scope.row.dailyChargingEnergy).toFixed(2) }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('dailyDischargingEnergy')"  sortable :sort-method="(a, b) => sortMethod(a.dailyDischargingEnergy, b.dailyDischargingEnergy)"
              label="日放电量(EMS)(KWH)" min-width="200" prop="dailyDischargingEnergy" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.dailyDischargingEnergy)">{{ Number(scope.row.dailyDischargingEnergy).toFixed(2) }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('dailyEnergySaving')"  sortable :sort-method="(a, b) => sortMethod(a.dailyEnergySaving, b.dailyEnergySaving)"
              label="日节能费用(EMS)(元)" min-width="220" prop="dailyEnergySaving" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.dailyEnergySaving)">{{ Number(scope.row.dailyEnergySaving).toFixed(2) }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('fireProtectionStatus')" label="消防状态" min-width="120" prop="fireProtectionStatus" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.fireProtectionStatus)">{{ scope.row.fireProtectionStatus }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('maxChargePower')"  sortable :sort-method="(a, b) => sortMethod(a.maxChargePower, b.maxChargePower)"
              label="最大允许充电功率(KW)" min-width="190" prop="maxChargePower" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.maxChargePower)">{{ Number(scope.row.maxChargePower).toFixed(2) }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('maxDischargePower')"  sortable :sort-method="(a, b) => sortMethod(a.maxDischargePower, b.maxDischargePower)"
              label="最大允许放电功率(KW)" min-width="190" prop="maxDischargePower" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.maxDischargePower)">{{ Number(scope.row.maxDischargePower).toFixed(2) }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('numberOfRacksInService')" label="当前在网簇数" min-width="140" prop="numberOfRacksInService" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.numberOfRacksInService)">{{ scope.row.numberOfRacksInService }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('numberOfTotalRacks')" label="堆内总簇数" min-width="120" prop="numberOfTotalRacks" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.numberOfTotalRacks)">{{ scope.row.numberOfTotalRacks }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('rack')" label="RACK 在线状态" min-width="300" prop="rack" align="center">
              <template slot-scope="scope">
                <span v-if="lookMaxMin">
                  <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.rack)">{{ scope.row.rack }}</span>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
                </span>
                <span v-else>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.rackOnlineStatusArray)">{{ scope.row.rackOnlineStatusArray.join() }}</span>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('rackFaultSummary')" label="系统一级报警汇总" min-width="400" prop="rackFaultSummary" align="center">
              <template slot-scope="scope">
                <span v-if="utils.checkObjectIsNotEmpty(scope.row.rackFaultSummary)">
                  <span v-if="scope.row.rackFaultSummary === '正常'">
                    <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'">{{ scope.row.rackFaultSummary }}</span>
                  </span>
                  <span v-else>
                    <span class="warning-color">{{ scope.row.rackFaultSummary }}</span>
                  </span>
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('rackWarningSummary')" label="系统一级报警汇总" min-width="400" prop="rackWarningSummary" align="center">
              <template slot-scope="scope">
                <span v-if="utils.checkObjectIsNotEmpty(scope.row.rackWarningSummary)">
                  <span v-if="scope.row.rackWarningSummary === '正常'">
                    <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'">{{ scope.row.rackWarningSummary }}</span>
                  </span>
                  <span v-else>
                    <span class="warning-color">{{ scope.row.rackWarningSummary }}</span>
                  </span>
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('rackAlarmSummary')" label="系统二级报警汇总" min-width="400" prop="rackAlarmSummary" align="center">
              <template slot-scope="scope">
                <span v-if="utils.checkObjectIsNotEmpty(scope.row.rackAlarmSummary)">
                  <span v-if="scope.row.rackAlarmSummary === '正常'">
                    <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'">{{ scope.row.rackAlarmSummary }}</span>
                  </span>
                  <span v-else>
                    <span class="alarm-color">{{ scope.row.rackAlarmSummary }}</span>
                  </span>
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('rackCriticalAlarmSummary')" label="系统三级报警汇总" min-width="400" prop="rackCriticalAlarmSummary" align="center">
              <template slot-scope="scope">
                <span v-if="utils.checkObjectIsNotEmpty(scope.row.rackCriticalAlarmSummary)">
                  <span v-if="lookMaxMin">
                    <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'">{{ scope.row.rackCriticalAlarmSummary }}</span>
                  </span>
                  <span v-else>
                    <span v-if="scope.row.rackCriticalAlarmSummary === '正常'">
                      <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'">{{ scope.row.rackCriticalAlarmSummary }}</span>
                    </span>
                    <span v-else>
                      <span class="critical-alarm-color">{{ scope.row.rackCriticalAlarmSummary }}</span>
                    </span>
                  </span>
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemCharge')" label="系统充放电状态" min-width="170" prop="systemCharge" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemCharge)">
                  {{ lookMaxMin ? scope.row.systemCharge : systemChargeList[scope.row.systemCharge] }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemEnableChargeEnergy')"  sortable :sort-method="(a, b) => sortMethod(a.systemEnableChargeEnergy, b.systemEnableChargeEnergy)"
              label="系统可充电量(KWH)" min-width="220" prop="systemEnableChargeEnergy" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemEnableChargeEnergy)">
                  {{ lookMaxMin ? scope.row.systemEnableChargeEnergy : Number(scope.row.systemEnableChargeEnergy).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemEnableDiscargeEnergy')"  sortable :sort-method="(a, b) => sortMethod(a.systemEnableDiscargeEnergy, b.systemEnableDiscargeEnergy)"
              label="系统可放电量(KWH)" min-width="220" prop="systemEnableDiscargeEnergy" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemEnableDiscargeEnergy)">
                  {{ lookMaxMin ? scope.row.systemEnableDiscargeEnergy : Number(scope.row.systemEnableDiscargeEnergy).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemEnableMaxChargeCurrent')"  sortable :sort-method="(a, b) => sortMethod(a.systemEnableMaxChargeCurrent, b.systemEnableMaxChargeCurrent)"
              label="系统允许最大充电电流(A)" min-width="250" prop="systemEnableMaxChargeCurrent" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemEnableMaxChargeCurrent)">
                  {{ lookMaxMin ? scope.row.systemEnableMaxChargeCurrent : Number(scope.row.systemEnableMaxChargeCurrent).toFixed(2) }}
                  </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemEnableMaxDischargeCurrent')"  sortable :sort-method="(a, b) => sortMethod(a.systemEnableMaxDischargeCurrent, b.systemEnableMaxDischargeCurrent)"
              label="系统允许最大放电电流(A)" min-width="250" prop="systemEnableMaxDischargeCurrent" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemEnableMaxDischargeCurrent)">
                  {{ lookMaxMin ? scope.row.systemEnableMaxDischargeCurrent : Number(scope.row.systemEnableMaxDischargeCurrent).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemInsulation')"  sortable :sort-method="(a, b) => sortMethod(a.systemInsulation, b.systemInsulation)"
              label="系统绝缘值(KΩ)" min-width="160" prop="systemInsulation" align="center">
              <template slot-scope="scope">
                <span v-if="lookMaxMin">
                  <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemLnsulation)">
                    {{ scope.row.systemLnsulation }}
                  </span>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
                </span>
                <span v-else>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemInsulation)">
                    {{ Number(scope.row.systemInsulation).toFixed(2) }}
                  </span>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMaxCellTemperature')"  sortable :sort-method="(a, b) => sortMethod(a.systemMaxCellTemperature, b.systemMaxCellTemperature)"
              label="系统最高电池温度" min-width="170" prop="systemMaxCellTemperature" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMaxCellTemperature)">
                  {{ lookMaxMin ? scope.row.systemMaxCellTemperature : Number(scope.row.systemMaxCellTemperature).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMaxCellVoltage')"  sortable :sort-method="(a, b) => sortMethod(a.systemMaxCellVoltage, b.systemMaxCellVoltage)"
              label="系统最高电池电压(℃)" min-width="190" prop="systemMaxCellVoltage" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMaxCellVoltage)">
                  {{ lookMaxMin ? scope.row.systemMaxCellVoltage : Number(scope.row.systemMaxCellVoltage).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMaxTempCellId')" label="系统最高温度电池所在位置" min-width="220" prop="systemMaxTempCellId" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMaxTempCellId)">{{ scope.row.systemMaxTempCellId }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMaxTempCellRackId')" label="系统最高温度电池所在Rack号" min-width="240" prop="systemMaxTempCellRackId" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMaxTempCellRackId)">{{ scope.row.systemMaxTempCellRackId }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMaxTempCellSlaveId')" label="系统最高温度电池所在组" min-width="220" prop="systemMaxTempCellSlaveId" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMaxTempCellSlaveId)">{{ scope.row.systemMaxTempCellSlaveId }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMaxVolCellId')" label="系统最高电压电池所在位置" min-width="220" prop="systemMaxVolCellId" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMaxVolCellId)">{{ scope.row.systemMaxVolCellId }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMaxVolCellRackId')" label="系统最高电压电池所在Rack号" min-width="240" prop="systemMaxVolCellRackId" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMaxVolCellRackId)">{{ scope.row.systemMaxVolCellRackId }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMaxVolCellSlaveId')" label="系统最高电压电池所在组" min-width="220" prop="systemMaxVolCellSlaveId" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMaxVolCellSlaveId)">{{ scope.row.systemMaxVolCellSlaveId }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMinCellTemperature')"  sortable :sort-method="(a, b) => sortMethod(a.systemMinCellTemperature, b.systemMinCellTemperature)"
              label="系统最低电池温度(℃)" min-width="190" prop="systemMinCellTemperature" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMinCellTemperature)">
                  {{ lookMaxMin ? scope.row.systemMinCellTemperature : Number(scope.row.systemMinCellTemperature).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMinCellVoltage')"  sortable :sort-method="(a, b) => sortMethod(a.systemMinCellVoltage, b.systemMinCellVoltage)"
              label="系统最低电池电压(mV)" min-width="190" prop="systemMinCellVoltage" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMinCellVoltage)">
                  {{ lookMaxMin ? scope.row.systemMinCellVoltage : Number(scope.row.systemMinCellVoltage).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMinTempCellId')" label="系统最低温度电池所在位置" min-width="220" prop="systemMinTempCellId" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMinTempCellId)">{{ scope.row.systemMinTempCellId }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMinTempCellRackId')" label="系统最低温度电池所在Rack号" min-width="240" prop="systemMinTempCellRackId" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMinTempCellRackId)">{{ scope.row.systemMinTempCellRackId }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMinTempCellSlaveId')" label="系统最低温度电池所在组" min-width="220" prop="systemMinTempCellSlaveId" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMinTempCellSlaveId)">{{ scope.row.systemMinTempCellSlaveId }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMinVolCellId')" label="系统最低电压电池所在位置" min-width="220" prop="systemMinVolCellId" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMinVolCellId)">{{ scope.row.systemMinVolCellId }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMinVolCellRackId')" label="系统最低电压电池所在Rack号" min-width="240" prop="systemMinVolCellRackId" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMinVolCellRackId)">{{ scope.row.systemMinVolCellRackId }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemMinVolCellSlaveId')" label="系统最低电压电池所在组" min-width="220" prop="systemMinVolCellSlaveId" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemMinVolCellSlaveId)">{{ scope.row.systemMinVolCellSlaveId }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemSoc')" sortable :sort-method="(a, b) => sortMethod(a.systemSoc, b.systemSoc)"
              label="系统SOC1(%)" min-width="140" prop="systemSoc" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemSoc)">
                  {{ lookMaxMin ? scope.row.systemSoc : Number(scope.row.systemSoc).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemSoh')"  sortable :sort-method="(a, b) => sortMethod(a.systemSoh, b.systemSoh)"
              label="系统SOH(%)" min-width="140" prop="systemSoh" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemSoh)">
                  {{ lookMaxMin ? scope.row.systemSoh : Number(scope.row.systemSoh).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemTotalCurrent')"  sortable :sort-method="(a, b) => sortMethod(a.systemTotalCurrent, b.systemTotalCurrent)"
              label="系统总电流(A)" min-width="140" prop="systemTotalCurrent" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemTotalCurrent)">
                  {{ lookMaxMin ? scope.row.systemTotalCurrent : Number(scope.row.systemTotalCurrent).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('systemTotalVoltage')"  sortable :sort-method="(a, b) => sortMethod(a.systemTotalVoltage, b.systemTotalVoltage)"
              label="系统总电压(V)" min-width="140" prop="systemTotalVoltage" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.systemTotalVoltage)">
                  {{ lookMaxMin ? scope.row.systemTotalVoltage : Number(scope.row.systemTotalVoltage).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('totalEnergySaving')"  sortable :sort-method="(a, b) => sortMethod(a.totalEnergySaving, b.totalEnergySaving)"
              label="总节能费用(EMS)(元)" min-width="180" prop="totalEnergySaving" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.totalEnergySaving)">
                  {{ lookMaxMin ? scope.row.totalEnergySaving : Number(scope.row.totalEnergySaving).toFixed(2) }}
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <el-table-column v-if="checkedColumnsSet.has('waterloggingStatus')" label="水浸状态" min-width="120" prop="waterloggingStatus" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-if="utils.checkObjectIsNotEmpty(scope.row.waterloggingStatus)">{{ scope.row.waterloggingStatus }}</span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column>
            <!-- <el-table-column v-if="checkedColumnsSet.has('type')" label="设备状态" min-width="120" prop="type" align="center">
              <template slot-scope="scope">
                {{ scope.row.type }}
                <span v-if="isNotEmpty(scope.row.type)">
                  <span class="error-color" v-if="scope.row.type === 1">异常</span>
                  <span :class="scope.row.type === 1 ? 'error-color' : 'normal-color'" v-else>正常</span>
                </span>
                <span :class="scope.row.type === 1 ? 'error-color' : 'ban-color'" v-else>无数据</span>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import historyDataApi from "@/api/history-data";
import { getDevices, getTotalConsoleLatestArticle } from '@/api/device';
import TimerPickerSettingsMixin from "@/views/mixin/TimerPickerSettingsMixin";
import { parseAseembleData } from "@/utils/parse";
import ChgDischgStatus from "@/components/chg-dischg-status";

const columns = [].concat($statics.ASSEMBLE_DATA_ITEM_LIST);

export default {
  computed: {
    ...mapGetters(['user']),
  },
  components: {
    ChgDischgStatus
  },
  mixins: [ TimerPickerSettingsMixin ],
  props: {
    deviceInfo: {
      type: Object,
      default: null
    },
    isSmallScreen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      checkBoxIcon: "el-icon-caret-right",
      utils: $utils,
      isShowCheckBoxGroup: false,
      lookMaxMin: false,
      checkAll: false,
      runStatusMap: $statics.MATCHINE_RUN_STATUS_MAP,
      systemStatusMap: $statics.SYSTEM_STATUS_MAP,
      selectedCodes: [],
      codes: [],
      deviceList: [],
      checkedColumns: [],
      defaultCheckedColumns: [],
      preCheckedColumns: [],
      checkedColumnsSet: new Set(),
      columns: columns,
      isIndeterminate: true,
      zeroWidth: "\u200b",
      utils: $utils,
      btnType: {
        chart: "",
        table: "primary"
      },
      nowType: "logassemble",
      height: 0,
      systemChargeList: [ "静置", "放电", "充电" ],
      code: null,
      list: [],
      multipleSelection: [],
      pageData: {
        current: 1,
        size: 999999999,
        total: 0
      },
      useImdependenceItem: false,
      isEmpty: false,
      sortMethod: function (a, b) {
        return a - b;
      }
    }
  },
  created () {
    console.log("assemble-data");
    this.height = document.body.scrollHeight - 160;

    const configs = this.user.configs;
    if ($statics.ASSEMBLE_DATA_CONFIG_REGX.test(configs)) {
      let assembleDataConfig = configs.match($statics.ASSEMBLE_DATA_CONFIG_REGX)[1];
      const checkedColumns = assembleDataConfig.split(",");
      this.checkedColumns = checkedColumns;
    } else {
      this.checkedColumns = [].concat($statics.DEFAULT_CHECKED_ASSEMBLE_DATA_ITEM_LIST);
    }

    this.checkedColumnsSet = new Set(this.checkedColumns);
    this.preCheckedColumns = [].concat(this.checkedColumns);
    this.defaultCheckedColumns = [].concat(this.checkedColumns);

    this.init();
  },
  methods: {
    async init () {
      
      await this.getDatas();

      const tel = this.user.tel || "";
      if ($statics.ASSEMBLE_DATA_CONFIG_REGX.test(tel)) {
        let assembleDataConfig = tel.match($statics.ASSEMBLE_DATA_CONFIG_REGX)[1];
        const assembleDataSelectedItemList = assembleDataConfig.split(",");
        this.useImdependenceItem = true;
        this.checkedColumns = assembleDataSelectedItemList;

        this.onSelectShowColumns();
      }
    },
    async getDatas () {
      try {
        this.loading = true;

        if (this.selectedCodes.length === 0) {
          const params1 = {
            page: 0,
            size: 5000,
            projectType: "1",
            order: "desc"
          }

          if (this.user.tenantId !== 0) {
            params1.businessId = this.user.businessId;
          }
          
          const res0 = await getDevices(params1);

          const codes = res0.data.map(obj => obj.code);
          this.deviceList = res0.data;
          this.codes = codes;
        }

        const params = this.getParams();
        const res = await getTotalConsoleLatestArticle(params);

        this.list = res.filter(obj => {
          const ret = this.parse(obj);
          return ret;
        });
        this.isEmpty = false;
      } catch (e) {
        this.isEmpty = true;
      } finally {
        this.loading = false;
      }
    },
    onSearch () {
      if (this.lookMaxMin) {
        this.getMaxMinData();
      } else {
        this.getDatas();
      }
    },
    async getMaxMinData () {
      try {
        this.loading = true;
        const params = this.getParams();
        const res = await historyDataApi.getLogAssembleCountMaxMin(params);
        this.list = res;
      } finally {
        this.loading = false;
      }
    },
    parse (obj) {
      if (!obj.information && !obj.emsInformation ) return false;
      const data = parseAseembleData(obj.information + obj.emsInformation);
      obj = Object.assign(obj, data);
      return true;
    },
    getParams (ids) {

      const params ={
        createTime: $utils.formatTime(this.timeRange[0], "YY-MM-DD 00:00:00"),
        updateTime: $utils.formatTime(this.timeRange[1], "YY-MM-DD HH:mm:ss"),
      };

      if (ids) {
        params.ids = ids;
      }
      // 如果时间没有数据，则把时间设置为最近一周
      // 这么设置的原因是后台如果不传入时间可能会有奇怪的问题
      if (this.timeRange === null) { 
        const weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - 7);
        this.timeRange = [weekAgo, new Date()];
      }

      if (this.user.tenantId !== 0) {
        params.businessId = this.user.businessId;
      }

        let tmpArr = [];

        for (const column of columns) {
          for (const checkedCol of this.checkedColumns) {
            if (column.value === checkedCol) {
              tmpArr.push(checkedCol);
            }
          }
        }

        const index = tmpArr.indexOf("airConditioningStatus");
        if (index > 0) {
          tmpArr.splice(index + 1, 0, "airConditioningStatus2");
        }

        const codes = (this.selectedCodes && this.selectedCodes.length > 0) ? this.selectedCodes : this.codes;
        params.exportFields = codes.join();

      return params;
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    handleCheckAllChange(val) {
      this.checkedColumns = val ? columns.map(obj => obj.value) : [];
      this.isIndeterminate = false;
    },
    handleCheckedColumnsChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === columns.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < columns.length;
    },
    hideCheckBoxGroup () {
      if (!this.isShowCheckBoxGroup) return;
      this.isShowCheckBoxGroup = false;
      this.checkBoxIcon = "el-icon-caret-right";
      this.checkedColumns = [].concat(this.preCheckedColumns);
      this.isIndeterminate = true;
      this.checkAll = this.checkedColumns.length === columns.length;
    },
    showHideCheckBoxGroup () {
      this.isShowCheckBoxGroup = !this.isShowCheckBoxGroup;
      if (this.isShowCheckBoxGroup) {
        this.checkBoxIcon = "el-icon-caret-bottom";
      } else {
        this.checkBoxIcon = "el-icon-caret-right";
      }
    },
    toDefaultCheckBox () {
      this.checkedColumns = [].concat(this.defaultCheckedColumns);
    },
    onSelectShowColumns () {
      const that = this;
      if (that.checkedColumns.length === 0) {
        that.$message.error("请至少选择一个项目！");
        return;
      }

      that.loading = true;
      that.checkedColumnsSet = new Set(that.checkedColumns);
      that.preCheckedColumns = [].concat(that.checkedColumns);
      that.hideCheckBoxGroup();
      
      that.$nextTick(() => {
        if (that.$refs.table) {
          that.$refs.table.doLayout();
        }
        that.loading = false;
      }, 300);
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-row-box {
  height: 35px;
}
.logassemble-btn {
  border-radius: 5px !important;
}

.error-color {
  color: #FF0000;
}

.columns-select-box {
  z-index: 1000;
  background: #FFFFFF;
  position: absolute;
  margin-top:5px;
  right: 2px;
  padding: 10px;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);

  ::v-deep .el-checkbox-group {
    height: 400px;
    overflow: auto;
  }

  .check-box-item-box {
    width: 300px;
    padding: 5px;
    text-align: left;
  }

  .checkbox-btns-box {
    margin-top: 10px;
    >div {
      display: inline-block;
      vertical-align: middle;
    }
    >div:first-child {
      text-align: left;
      width: 112px;
    }
  }
}
</style>