import bizApi from "@/api/business";
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['user']),
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        dataObj: {
            type: Object,
            default: {}
        },
        span: {
            type: Number,
            default: 4
        }
    },
    data () {
        return {
            companyDisabled: true,
            roleGroups: [].concat($statics.ROLE_GROUP_LIST),
            companyList: []
        }
    },
    created () {
        if (this.user.tenantId !== 0) {
            this.roleGroups.shift();
        }
    },
    methods: {
        onReselect (businessId = "") {
            this.dataObj.businessId = businessId;
        },
        async onSelectGroup (tenantId) {
            this.companyList = [];
            this.companyDisabled = !$utils.checkNumberIsNotEmpty(this.dataObj.tenantId);

            const res = await bizApi.list({
                tenantId,
                page: 0,
                size: 9999999
            });

            this.companyList = res.data;
            this.$emit("load", this.companyList);
        },
        onSelect (tenantId) {
            this.companyDisabled = false;
            this.dataObj.businessId = "";
            this.onSelectGroup(tenantId);
            this.onChange();
        },
        onChange () {
            this.$emit("change");
        },
    }
}