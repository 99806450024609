<template>
  <div v-loading="loading">
    <el-form ref="form" :model="form" :rules="rules" label-width="130px" class="edit-items-box">
      <el-form-item class="m-r-120" prop="code" label="设备编号">
        <el-input v-model="form.code" class="is-readonly" maxlength="6" :disabled="data && data.type === 1" size="small" placeholder="请输入设备编号" />
      </el-form-item>
      <el-form-item class="m-r-120" prop="iccId" label="SIM卡ICCID">
        <el-input v-model="form.iccId" clearable size="small" maxlength="15" placeholder="请输入SIM卡ICCID" />
      </el-form-item>
      <el-form-item class="m-r-120" prop="imei" label="SIM卡IMEI">
        <el-input v-model="form.imei" clearable size="small" maxlength="15" placeholder="请输入SIM卡IMEI" />
      </el-form-item>
      <el-form-item class="m-r-120" prop="activationTime" label="SIM卡激活时间">
        <el-date-picker
          v-model="form.activationTime"
          :disabled="data && data.type === 1"
          type="datetime"
          clearable
          size="mini"
          placeholder="选择SIM卡激活时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="m-r-120" prop="expireTime" label="SIM卡过期时间">
        <el-date-picker
          v-model="form.expireTime"
          type="datetime"
          clearable
          size="mini"
          placeholder="选择SIM卡过期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="m-r-120" prop="annualFee" label="SIM卡年费">
        <el-input v-model="form.annualFee" clearable size="small" maxlength="15" placeholder="请输入 SIM卡年费">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
    </el-form>
    <dialog-footer reset-access="device-edit-reset-btn" submit-access="device-edit-edit-btn" @reset="onReset" @submit="onSubmit" />
  </div>
</template>
<script>
import BusinessEdit from "@/components/business/edit";

import simApi from "@/api/sim";
import bizApi from "@/api/business";

export default {
  components: {
    BusinessEdit
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      company: null,
      companyList: [],
      form: {
        code: "",
        iccId: "",
        imei: "",
        activationTime: null,
        expireTime: null,
        annualFee: "",
        type: 0
      },
      rules: {
        code:  [
          { required: true, message: '请输入设备编号', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value.length !== 6 || isNaN(value)) {
                callback("设备编号请使用必须是六位数字");
                return;
              }
              callback();
            }
          }
        ],
        iccId:  [
          { required: true, message: '请输入ICCID', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (isNaN(value)) {
                callback("请输入数字");
              } else {
                callback();
              }
            }
          }
        ],
        imei:  [
          { required: true, message: '请输入IMEI', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (isNaN(value)) {
                callback("请输入数字");
              } else {
                callback();
              }
            }
          }
        ],
        activationTime:  [
          { required: true, message: '请选择SIM卡激活时间', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              let expireTime = this.form.expireTime;
              if (!expireTime) {
                callback();
                return;
              }

              let xTime = expireTime.getTime();
              let aTime = value.getTime();

              if (aTime > xTime) {
                callback("SIM卡激活时间不能早于SIM卡过期时间");
              } else {
                this.$refs.form.clearValidate(['activationTime', 'expireTime']);
                callback();
              }
            }
          }
        ],
        expireTime:  [
          { required: true, message: '请选择SIM卡过期时间', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              let activationTime = this.form.activationTime;
              if (!activationTime) {
                callback();
                return;
              }

              let aTime = activationTime.getTime();
              let xTime = value.getTime();

              if (aTime > xTime) {
                callback("SIM卡激活时间不能早于SIM卡过期时间");
              } else {
                this.$refs.form.clearValidate(['activationTime', 'expireTime']);
                callback();
              }
            }
          }
        ],
        annualFee:  [
          { required: true, message: '请输入SIM卡年费', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (isNaN(value)) {
                callback("请输入数字");
              } else {
                callback();
              }
            }
          }
        ],
      }
    }
  },
  created () {
    console.log("edit-sim");
    if (this.data) {
      this.form.code = this.data.code;
      this.form.companyName = this.data.companyName;
      this.getDatas();
    }
  },
  methods: {
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    async getDatas () {
      try {
        this.loading = true;
        const res = await simApi.detail(this.data.id);
        this.form = res;
        const bizRes = await bizApi.detail(this.form.businessId);
        this.form.tenantId = bizRes.tenantId;
        await this.$refs.bizEdit.onSelectGroup(this.form.tenantId);
        this.$refs.form.clearValidate(Object.keys(this.form));
      } finally {
        this.loading = false;
      }
    },
    async onReset () {
      if (this.data && this.data.id) {
        this.form = Object.assign(this.form, JSON.parse(cache));
      } else {
        this.$refs.form.resetFields();
      }
      this.$forceUpdate();
    },
    onSubmit () { // 提交函数
      this.form.tenantId = String(this.form.tenantId);
      this.$refs.form.validate((valid) => { // 数据校验
        if (!valid) { // 未通过校验则不继续执行
          return;
        }

        const submitData = Object.assign({}, this.form);
        submitData.code = this.form.code.toLocaleUpperCase(); // code 大写处理
        submitData.activationTime = $utils.formatTime(submitData.activationTime);
        submitData.expireTime = $utils.formatTime(submitData.expireTime);

        if (this.data && this.data.id ) {
          simApi.edit(submitData).then(
            res => {
              this.$message({
                message: '修改成功！',
                type: 'success'
              });
              this.addDevicesVisible = false;
              this.closeLoading();
            })
          .catch (err => {
            this.$message({
              message: err.response.data.message,
              type: 'error'
            });
          });
        } else {
          simApi.add(submitData).then(
            res => {
              this.$message({
                message: '添加成功！',
                type: 'success'
              });
              this.addDevicesVisible = false;
              this.closeLoading();
            })
          .catch (err => {
            this.$message({
              message: err.response.data.message,
              type: 'error'
            });
          });
        }
      });
    },
    closeLoading () {
      this.$emit("close-edit-modal");
    }
  }
}
</script>