import request from '@/utils/request';

// 列表
export const list = (params) => {
    const rand = Date.now();
    return request.get(`sims?${rand}`, { params });
}
// 增加
export const add = (data) => {
    const rand = Date.now();
    return request.put(`sims/sim?${rand}`, data);
}
// 编辑
export const edit = (data) => {
    const rand = Date.now();
    return request.post(`sims/sim?${rand}`, data);
}

// 删除
export const del = (id) => {
    const rand = Date.now();
    return request.get(`/sims/delete/${id}?${rand}`);
}

// 详情
export const detail = (id) => {
    const rand = Date.now();
    return request.get(`/sims/${id}?${rand}`);
}

export default {
    list,
    add, 
    edit,
    del,
    detail
}