var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs-data-box tabs-data-box2" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "80px" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "固件类别", prop: "description" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "small",
                            clearable: "",
                            placeholder: "请选择固件类别",
                          },
                          on: { change: _vm.onSearch },
                          model: {
                            value: _vm.searchParams.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "type", $$v)
                            },
                            expression: "searchParams.type",
                          },
                        },
                        _vm._l(_vm.typeList, function (node) {
                          return _c("el-option", {
                            key: node.value,
                            attrs: {
                              in: "",
                              label: node.label,
                              value: node.value,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "right-btns", attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        icon: "el-icon-upload",
                        type: "primary",
                      },
                      on: { click: _vm.onOpenDialog },
                    },
                    [_vm._v(_vm._s(`上传固件`))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            height: _vm.tableHeight,
            "header-cell-style": {
              background: "#F7F7F7",
              color: "#2d2d2d",
              "text-align": "center",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "50",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.$index +
                            1 +
                            (_vm.pageData.current - 1) * _vm.pageData.size
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "edition",
              label: "固件类别",
              width: "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.typeMap[scope.row.type].label) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "edition", label: "固件版本", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { prop: "deviceName", label: "文件名", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { prop: "size", label: "文件大小", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.size) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "description", label: "固件说明" },
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "上传时间", width: "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatTime")(scope.row.createTime)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          !_vm.utils.userReadOnly(_vm.user)
            ? _c("el-table-column", {
                attrs: { label: "操作", width: "150", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c("el-button", {
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  icon: "el-icon-edit",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEditFirmware(scope.row)
                                  },
                                },
                              }),
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: {
                                    icon: "el-icon-info",
                                    "icon-color": "#FF0000",
                                    "confirm-button-type": "danger",
                                    title: "删除操作不可恢复，您确认要删除么？",
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.onDelFirmware(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "reference",
                                      type: "danger",
                                      size: "mini",
                                      icon: "el-icon-delete",
                                    },
                                    slot: "reference",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3154568717
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("el-pagination", {
        staticClass: "text-right m-t-10",
        attrs: {
          background: "",
          layout: "sizes, prev, pager, next, jumper",
          "page-sizes": [20, 50, 100],
          "page-size": _vm.pageData.size,
          total: _vm.pageData.total,
          "current-page": _vm.pageData.current,
        },
        on: {
          "size-change": _vm.onSizeChange,
          "current-change": _vm.pageChange,
        },
      }),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.name,
                "close-on-press-escape": false,
                "show-close": "",
                "close-on-click-modal": true,
                visible: _vm.dialogVisible,
                width: "600px",
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseDialog()
                },
              },
            },
            [
              _c("edit", {
                ref: "edit",
                attrs: {
                  data: _vm.data,
                  "is-add": _vm.isAdd,
                  type: _vm.type,
                  title: _vm.name,
                  visible: _vm.dialogVisible,
                },
                on: {
                  "close-edit-modal": function ($event) {
                    return _vm.onCloseDialog(true)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }