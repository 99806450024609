var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "edit-items-box",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "130px" },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "m-r-120",
              attrs: { prop: "code", label: "设备编号" },
            },
            [
              _c("el-input", {
                staticClass: "is-readonly",
                attrs: {
                  maxlength: "6",
                  disabled: _vm.data && _vm.data.type === 1,
                  size: "small",
                  placeholder: "请输入设备编号",
                },
                model: {
                  value: _vm.form.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "code", $$v)
                  },
                  expression: "form.code",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "m-r-120",
              attrs: { prop: "iccId", label: "SIM卡ICCID" },
            },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "small",
                  maxlength: "15",
                  placeholder: "请输入SIM卡ICCID",
                },
                model: {
                  value: _vm.form.iccId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "iccId", $$v)
                  },
                  expression: "form.iccId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "m-r-120",
              attrs: { prop: "imei", label: "SIM卡IMEI" },
            },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "small",
                  maxlength: "15",
                  placeholder: "请输入SIM卡IMEI",
                },
                model: {
                  value: _vm.form.imei,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "imei", $$v)
                  },
                  expression: "form.imei",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "m-r-120",
              attrs: { prop: "activationTime", label: "SIM卡激活时间" },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  disabled: _vm.data && _vm.data.type === 1,
                  type: "datetime",
                  clearable: "",
                  size: "mini",
                  placeholder: "选择SIM卡激活时间",
                },
                model: {
                  value: _vm.form.activationTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "activationTime", $$v)
                  },
                  expression: "form.activationTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "m-r-120",
              attrs: { prop: "expireTime", label: "SIM卡过期时间" },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  clearable: "",
                  size: "mini",
                  placeholder: "选择SIM卡过期时间",
                },
                model: {
                  value: _vm.form.expireTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "expireTime", $$v)
                  },
                  expression: "form.expireTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "m-r-120",
              attrs: { prop: "annualFee", label: "SIM卡年费" },
            },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    clearable: "",
                    size: "small",
                    maxlength: "15",
                    placeholder: "请输入 SIM卡年费",
                  },
                  model: {
                    value: _vm.form.annualFee,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "annualFee", $$v)
                    },
                    expression: "form.annualFee",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("dialog-footer", {
        attrs: {
          "reset-access": "device-edit-reset-btn",
          "submit-access": "device-edit-edit-btn",
        },
        on: { reset: _vm.onReset, submit: _vm.onSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }