import request from '@/utils/request';

// 列表
export const list = (params) => {
    const rand = Date.now();
    return request.get(`QrCode/list?${rand}`, { params });
}

// 新增
export const add = (data) => {
    const rand = Date.now();
    return request.post(`QrCode/save?${rand}`, data);
}
// 详情
export const detail = (id) => {
    const rand = Date.now();
    return request.get(`QrCode/${id}/details?${rand}`);
}

export default {
    list,
    add,
    detail
}