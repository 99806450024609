var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tabs-data-box tabs-data-box2" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      },
      [
        _c(
          "el-table",
          {
            ref: "table",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.alarms,
              height: _vm.height ? _vm.height : undefined,
              "header-cell-style": {
                background: "#F7F7F7",
                color: "#2d2d2d",
                "text-align": "center",
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                label: "设备编号",
                prop: "productId",
                width: "100",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "上报时间",
                prop: "createTime",
                width: "160",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.updateTime || scope.row.createTime) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "设备告警", prop: "warningData" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.warning == null
                        ? _c("div")
                        : scope.row.warning.length > 0
                        ? _c("div", [
                            scope.row.warning[0]
                              ? _c("span", [_vm._v("总压过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[1]
                              ? _c("span", [_vm._v("总压过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[2]
                              ? _c("span", [_vm._v("总压过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[3]
                              ? _c("span", [_vm._v("总压过低一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[4]
                              ? _c("span", [_vm._v("总压过低二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[5]
                              ? _c("span", [_vm._v("总压过低三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[6]
                              ? _c("span", [_vm._v("单体过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[7]
                              ? _c("span", [_vm._v("单体过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[8]
                              ? _c("span", [_vm._v("单体过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[9]
                              ? _c("span", [_vm._v("单体过低一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[10]
                              ? _c("span", [_vm._v("单体过低二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[11]
                              ? _c("span", [_vm._v("单体过低三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[12]
                              ? _c("span", [_vm._v("高段压差过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[13]
                              ? _c("span", [_vm._v("高段压差过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[14]
                              ? _c("span", [_vm._v("高段压差过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[15]
                              ? _c("span", [_vm._v("中段压差过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[16]
                              ? _c("span", [_vm._v("中段压差过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[17]
                              ? _c("span", [_vm._v("中段压差过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[18]
                              ? _c("span", [_vm._v("低段压差过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[19]
                              ? _c("span", [_vm._v("低段压差过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[20]
                              ? _c("span", [_vm._v("低段压差过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[21]
                              ? _c("span", [_vm._v("辅助压差过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[22]
                              ? _c("span", [_vm._v("辅助压差过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[23]
                              ? _c("span", [_vm._v("辅助压差过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[24]
                              ? _c("span", [_vm._v("充电电流过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[25]
                              ? _c("span", [_vm._v("充电电流过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[26]
                              ? _c("span", [_vm._v("充电电流过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[27]
                              ? _c("span", [_vm._v("放电电流过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[28]
                              ? _c("span", [_vm._v("放电电流过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[29]
                              ? _c("span", [_vm._v("放电电流过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[30]
                              ? _c("span", [_vm._v("反充电流过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[31]
                              ? _c("span", [_vm._v("反充电流过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[32]
                              ? _c("span", [_vm._v("反充电流过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[33]
                              ? _c("span", [_vm._v("SOC值过低一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[34]
                              ? _c("span", [_vm._v("SOC值过低二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[35]
                              ? _c("span", [_vm._v("SOC值过低三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[36]
                              ? _c("span", [_vm._v("SOH值过低一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[37]
                              ? _c("span", [_vm._v("SOH值过低二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[38]
                              ? _c("span", [_vm._v("SOH值过低三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[39]
                              ? _c("span", [_vm._v("继电器温度过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[40]
                              ? _c("span", [_vm._v("继电器温度过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[41]
                              ? _c("span", [_vm._v("继电器温度过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[42]
                              ? _c("span", [_vm._v("充电温度过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[43]
                              ? _c("span", [_vm._v("充电温度过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[44]
                              ? _c("span", [_vm._v("充电温度过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[45]
                              ? _c("span", [_vm._v("放电温度过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[46]
                              ? _c("span", [_vm._v("放电温度过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[47]
                              ? _c("span", [_vm._v("放电温度过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[48]
                              ? _c("span", [_vm._v("充电温度过低一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[49]
                              ? _c("span", [_vm._v("充电温度过低二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[50]
                              ? _c("span", [_vm._v("充电温度过低三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[51]
                              ? _c("span", [_vm._v("放电温度过低一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[52]
                              ? _c("span", [_vm._v("放电温度过低二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[53]
                              ? _c("span", [_vm._v("放电温度过低三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[54]
                              ? _c("span", [_vm._v("温差过大一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[55]
                              ? _c("span", [_vm._v("温差过大二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[56]
                              ? _c("span", [_vm._v("温差过大三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[57]
                              ? _c("span", [_vm._v("均衡温度过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[58]
                              ? _c("span", [_vm._v("均衡温度过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[59]
                              ? _c("span", [_vm._v("均衡温度过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[60]
                              ? _c("span", [_vm._v("绝缘电阻过低一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[61]
                              ? _c("span", [_vm._v("绝缘电阻过低二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[62]
                              ? _c("span", [_vm._v("绝缘电阻过低三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[63]
                              ? _c("span", [_vm._v("总压采样异常一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[64]
                              ? _c("span", [_vm._v("总压采样异常二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[65]
                              ? _c("span", [_vm._v("总压采样异常三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[66]
                              ? _c("span", [_vm._v("静置温度过高一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[67]
                              ? _c("span", [_vm._v("静置温度过高二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[68]
                              ? _c("span", [_vm._v("静置温度过高三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[69]
                              ? _c("span", [_vm._v("静置温度过低一级告警 ")])
                              : _vm._e(),
                            scope.row.warning[70]
                              ? _c("span", [_vm._v("静置温度过低二级告警 ")])
                              : _vm._e(),
                            scope.row.warning[71]
                              ? _c("span", [_vm._v("静置温度过低三级告警 ")])
                              : _vm._e(),
                            scope.row.warning[72]
                              ? _c("span", [_vm._v("主正接触器无法吸合 ")])
                              : _vm._e(),
                            scope.row.warning[73]
                              ? _c("span", [_vm._v("主正接触器粘连故障 ")])
                              : _vm._e(),
                            scope.row.warning[74]
                              ? _c("span", [_vm._v("主负接触器无法吸合 ")])
                              : _vm._e(),
                            scope.row.warning[75]
                              ? _c("span", [_vm._v("主负接触器粘连故障 ")])
                              : _vm._e(),
                            scope.row.warning[76]
                              ? _c("span", [_vm._v("充电接触器无法吸合 ")])
                              : _vm._e(),
                            scope.row.warning[77]
                              ? _c("span", [_vm._v("充电接触器粘连故障 ")])
                              : _vm._e(),
                            scope.row.warning[78]
                              ? _c("span", [_vm._v("预热接触器无法吸合 ")])
                              : _vm._e(),
                            scope.row.warning[79]
                              ? _c("span", [_vm._v("预热接触器粘连故障 ")])
                              : _vm._e(),
                            scope.row.warning[80]
                              ? _c("span", [_vm._v("风扇接触器无法吸合 ")])
                              : _vm._e(),
                            scope.row.warning[81]
                              ? _c("span", [_vm._v("风扇接触器粘连故障 ")])
                              : _vm._e(),
                            scope.row.warning[82]
                              ? _c("span", [_vm._v("预充接触器无法吸合 ")])
                              : _vm._e(),
                            scope.row.warning[83]
                              ? _c("span", [_vm._v("预充接触器粘连故障 ")])
                              : _vm._e(),
                          ])
                        : _c("div", [_vm._v("设备无告警")]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right-btns", staticStyle: { "margin-top": "15px" } },
          [
            _c(
              "el-button",
              {
                attrs: {
                  icon: "el-icon-refresh",
                  type: "primary",
                  size: "small",
                },
                on: { click: _vm.getDatas },
              },
              [_vm._v("刷新数据")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }