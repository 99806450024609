<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="版本号" prop="edition">
        <div class="parting-input-box">
          <el-input :disabled="!isAdd" v-model="major" size="small" maxlength="2" @blur="setMajor"></el-input>
        </div>
        <div class="parting-input-box parting-input-point-box">
          <el-input :disabled="!isAdd" v-model="minor" size="small" maxlength="2" @blur="setMinor"></el-input>
        </div>
        <div class="parting-input-box parting-input-point-box">
          <el-input :disabled="!isAdd" v-model="patch" size="small" maxlength="4" @blur="setPatch"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="固件说明" prop="description">
        <el-input v-model="form.description" size="small"></el-input>
      </el-form-item>
      <el-form-item label="固件类别" prop="type">
        <el-select :disabled="!isAdd" size="small" clearable v-model="form.type" placeholder="请选择固件类别">
          <el-tooltip v-for="type in typeList" in :key="type.value"
          class="item" effect="dark" :content="type.starts ? `${type.label}的固件包 请使用以 “${type.starts}” 开头的文件名` : '无说明'" placement="right">
          <el-option :label="type.label" :value="type.value"></el-option>
        </el-tooltip>
        </el-select>
      </el-form-item>
      <el-form-item :label="labelName" prop="file">
        <template v-if="data == null">
          <el-upload
            class="upload-demo"
            ref="upload"
            v-model="form.file"
            action="/api/devices/wrap/uploadFile"
            :data="form"
            :headers="headers"
            accept=".bin"
            :on-error="handleError"
            :on-change="handleChange"
            :on-success="handleSuccess"
            :file-list="fileList"
            :multiple="false"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            <div>只能上传 bin 文件，且不超过 128 K</div>
          </el-upload>
        </template>
        <template v-else>
          {{ data.deviceName }}
        </template>
      </el-form-item>
    </el-form>
    <dialog-footer @reset="onCancel" @submit="onSubmit" />
  </div>
</template>
<script>
import { getToken } from '@/utils/auth';
import { updateFirmware } from "@/api/firmware";

export default {
  props: {
    isAdd: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      utils: $utils,
      form: {
        edition: "",
        description: "",
        type: "",
        file: null
      },
      typeList: [].concat($statics.FIRMWARE_TYPE_LIST).slice(0, 7),
      version: [],
      labelName: "",
      major: "",
      minor: "",
      patch: "",
      fileSize: 0,
      fileList: [],
      rules: {
        edition: [
          // { required: true, message: '请输入版本号', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              for (const part of this.version) {
                if (isNaN(part)) {
                  callback("版本号请使用数字");
                  return;
                }
                if (/[\t\n ]/.test(part)) {
                  callback("版本号中请勿使用空白字符（空格、tab键等）");
                  return;
                }
              }
              for (const part of this.version) {
                if (!part && (part !== 0)) {
                  callback("版本号不完整");
                  return;
                }
              }

              if (this.version.length < 3) {
                callback('版本号不完整');
                return;
              }

              callback();
            },
            trigger: 'blur'
          }
        ],
        type: [
          { required: true, message: '请选择固件类型', trigger: 'blur' },
          {
            validator: (a1, a2, callback) => {
              if (!this.isAdd) { // 如果是编辑状态，忽略文件名校验
                callback();
                return;
              }
              const { type } = this.form;
              if (this.form.file) {
                const { name } = this.form.file;
                const res = this.checkFileName(type, name);
                if (res) {
                  callback(res);
                  return;
                }
              }
              callback();
            }
          }
        ],
        file: [
          { required: true, message: '请选择一个 bin 文件', trigger: 'blur' },
          {
            validator: (a1, a2, callback) => {
              if (!this.isAdd) { // 如果是编辑状态，忽略文件名校验
                callback();
                return;
              }
              const { type, file: { name } } = this.form;
              const res = this.checkFileName(type, name);
              if (res) {
                callback(res);
                return;
              }

              if (this.fileSize > 1024 * 256) {
                callback("文件大小不能超过 256 K");
                return;
              }
              callback();
            }
          }
        ]
      }
    }
  },
  computed: {
    headers: function () {
      const token = getToken();
      return { 
        Authorization: token
      };
    }
  },
  created () {
    if (this.data) {
      this.form.edition = this.data.edition;
      this.version = this.form.edition.split(".");
      this.major = this.version[0];
      this.minor = this.version[1];
      this.patch = this.version[2];
      this.form.description = this.data.description;
      this.form.file = this.data.deviceName;
      this.form.type = String(this.data.type);

      this.labelName = "文件名";
    } else {
      this.labelName = "上传固件";
    }
  },
  methods: {
    handleChange(file, fileList) {
      this.form.file = file;
      this.fileSize = file.size;

      this.fileList = [file];

      this.$refs.form.validateField("file", valid => {
        if (!valid) {
          this.$refs.form.clearValidate(["file", "type"]);
        }
      });
    },
    handleSuccess () {
      this.$emit("close-edit-modal");
    },
    handleError (res) {
      const err = JSON.parse(res.message);
      this.$message.error(err.message);
    },
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    setMajor () {
      this.version[0] = this.major;
    },
    setMinor () {
      this.version[1] = this.minor;
    },
    setPatch () {
      this.version[2] = this.patch;
    },
    onCancel () {
      if (this.data) {
        this.form.edition = this.data.edition;
        let version = this.form.edition.split(".");
        this.major = version[0];
        this.minor = version[1];
        this.patch = version[2];

        this.form.description = this.data.description;
        this.form.file = this.data.deviceName;
      } else {
        this.$refs.form.resetFields();
        this.major = "";
        this.minor = "";
        this.patch = "";
      }
    },
    onSubmit () {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }

        this.form.edition = this.version.join(".");
        if (this.data === null) {
          this.$refs.upload.submit();
        } else {
          try {
            const data = Object.assign({
              id: this.data.id
            }, this.form);
            delete data.file;
            delete data.edition;
            await updateFirmware(data);
            this.handleSuccess();
          } catch (e) {
            this.$message.error(e.response.data.message);
          }
        }
      });
    },
    checkFileName (type, name) {

      if (!type) {
        return "请先选择固件类型";
      }

      const starts = $statics.FIRMWARE_TYPE_MAP[type];

      if (!starts) {
        return "固件类型和文件名不匹配，请确认！";
      }

      const regx = new RegExp(`^${starts.starts}_(\\S+)\.bin$`);
      let part = name.match(regx);

      if (part === null) {
        return "固件类型和文件名不匹配，请确认！";
      } else {
        this.$refs.form.clearValidate(["type"]);

        part = part[1];

        if (part.length > 30) {
          return "文件名长度(不含前缀)请小于30个字符";
        }
        if (!/^[0-9A-Z_]+$/.test(part)) {
          return "文件名只能包含大写字母、数字、下划线";
        }

        this.$refs.form.clearValidate(["file"]);
      }
    }
  }
}
</script>