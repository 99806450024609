var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "big-data-container",
    },
    [
      _c(
        "div",
        { staticClass: "content", style: `height:${_vm.height}px;` },
        [
          !_vm.loading
            ? _c(
                "el-tabs",
                {
                  staticClass: "device-detail-tabs",
                  style: `height:${_vm.height - 30}px;`,
                  attrs: { "tab-position": "left" },
                  on: { "tab-click": _vm.onTabClick },
                  model: {
                    value: _vm.tabName,
                    callback: function ($$v) {
                      _vm.tabName = $$v
                    },
                    expression: "tabName",
                  },
                },
                [
                  _vm.isAdmin
                    ? _c(
                        "el-tab-pane",
                        {
                          staticStyle: { height: "100%" },
                          attrs: { label: "固件管理", name: "firmware" },
                        },
                        [
                          _vm.tabName === "firmware"
                            ? _c("firmware")
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isAdmin
                    ? _c(
                        "el-tab-pane",
                        {
                          staticStyle: { height: "100%" },
                          attrs: { label: "参数配置", name: "config-log" },
                        },
                        [
                          _vm.tabName === "config-log"
                            ? _c("config-log")
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isAdmin
                    ? _c(
                        "el-tab-pane",
                        {
                          staticStyle: { height: "100%" },
                          attrs: { label: "SIM卡管理", name: "sim" },
                        },
                        [_vm.tabName === "sim" ? _c("sim") : _vm._e()],
                        1
                      )
                    : _vm._e(),
                  _vm.isAdmin
                    ? _c(
                        "el-tab-pane",
                        {
                          staticStyle: { height: "100%" },
                          attrs: { label: "产品二维码", name: "qrcode" },
                        },
                        [_vm.tabName === "qrcode" ? _c("qrcode") : _vm._e()],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { height: "100%" },
                      attrs: { label: "总控数据", name: "assemble" },
                    },
                    [
                      _vm.tabName === "assemble"
                        ? _c("assemble-data")
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { height: "100%" },
                      attrs: { name: "alarms" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _vm.alarms.length > 0
                            ? _c(
                                "el-badge",
                                {
                                  staticClass: "item",
                                  attrs: { value: _vm.alarms.length, max: 99 },
                                },
                                [_vm._v(" 告警数据 ")]
                              )
                            : _c("div", [_vm._v("告警数据")]),
                        ],
                        1
                      ),
                      _vm.tabName === "alarms" ? _c("alarms") : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }