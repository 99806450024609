<template>
  <div class="tabs-data-box tabs-data-box2">
    <div  class="right-btns m-b-20">
      <el-button type="primary" size="mini" icon="el-icon-upload" @click="onShowDialog('upConfigLog')">上传配置文件</el-button>
      <el-button class="search-box-btn" type="primary" size="mini" icon="el-icon-search" @click="onSearch">查询</el-button>
    </div>
    <div v-loading="loading">
      <el-table :data="tableData" style="width: 100%" :height="tableHeight" :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column prop="createTime" label="上传时间" width="180"></el-table-column>
        <el-table-column prop="fileName" label="文件名" width="200"></el-table-column>
        <el-table-column prop="description" label="文件描述">
          <template slot-scope="scope"><div v-html="scope.row.description"></div></template>
        </el-table-column>
        <el-table-column prop="adminMobile" label="操作" :width="actionWidth" align="center" fixed="right" width="80">
          <template slot-scope="scope">
            <el-popconfirm
              icon="el-icon-info"
              icon-color="#FF0000"
              confirm-button-type="danger"
              title="删除操作不可恢复，您确认要删除么？"
              @confirm="onDel(scope.row.id)"
            >
              <el-button type="danger" slot="reference" size="mini" icon="el-icon-delete" />
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
        :page-sizes="[20, 50, 100]" @size-change="onSizeChange"
        :page-size="pageData.size" :total="pageData.total" :current-page="pageData.current" @current-change="pageChange" />
    </div>
    <el-dialog title="上传测试记录" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" v-if="dialogVisible.upConfigLog" :visible="dialogVisible.upConfigLog"
      @close="onCloseDialog('upConfigLog')" width="550px">
      <up-config-log ref="upConfigLog" :height="height"></up-config-log>
    </el-dialog>
  </div>
</template>
<script>
import UpConfigLog from "./up-config-log";

import { fileList, downloadTestLog, delTestLog } from "@/api/device";

export default {
  components: {
    UpConfigLog
  },
  data () {
    return {
      loading: false,
      searchParams: {
        code: ""
      },
      btnTypes: $statics.PROJECT_TYPE_LIST,
      btnTypes2: [],
      tableData: [],
      dialogVisible: {
        upConfigLog: false
      },
      rangeTime: [],
      pageData: {
        current: 1,
        size: 20,
        total: 0
      }
    }
  },
  created () {
    console.log("config-log");
    this.getDatas();
    this.btnTypes2 = [].concat(this.btnTypes);
    this.btnTypes2.splice(5, 0, {});
  },
  mounted () {
    this.tableHeight = document.body.scrollHeight - 205;
  },
  methods: {
    onSizeChange (e) {
      this.pageData.size = e;
      this.pageData.current = 1;
      this.getDatas();
    },
    pageChange(e) {
      this.pageData.current = e;
      this.getDatas();
    },
    onSearch () {
      this.pageData.current = 1;
      this.getDatas();
    },
    async getDatas () {
      this.loading = true;
      try {
        const params = Object.assign({
        }, this.pageData);
        const res = await fileList(params);
        this.tableData = res.data.map(obj => {
          obj.description = obj.description.replace(/(\r\n|\r|\n)/g, "<br />");
          obj.createTime = $utils.formatTime(obj.createTime);
          return obj;
        });
        this.pageData = res.paging;
      } catch {
        this.tableData = [];
        this.pageData = {
          current: 1,
          size: this.pageData.size,
          total: 0
        };
      } finally {
        this.loading = false;
      }
    },
    async onDownload ({fileName}) {
      try {
        const res = await downloadTestLog(fileName);
        $utils.exportExcel(res, fileName);
      } catch(e) {
        this.$message.error(e.response.data.message || "文件下载失败");
        console.log(e);
      }
    },
    async onDel (id) {
      try {
        this.loading = true;
        const res = await delTestLog(id);
        this.getDatas();
      } finally {
        this.loading = false;
      }
    },
    onShowDialog (name) {
      this.dialogVisible[name] = true;
    },
    async onCloseDialog (refName, ignore) {
      // 通过 emit 发送的关闭请求一律通过
      // 如果点击 close 图标则一律直接关闭
      if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
        // 设置 visable 后,会重新触发 close 所以需要先 remove edit
        delete this.$refs[refName];
        this.dialogVisible[refName] = false;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.tabs-data-box {
  width: 100%;
}
</style>