<template>
  <div class="tabs-data-box tabs-data-box2">
    <el-form label-width="80px">
      <el-row>
        <el-col :span="span">
          <el-form-item label="ICCID">
            <el-input v-model="searchParams.iccId" clearable size="mini" placeholder="请输入设备编号" @change="onSearch" />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="IMEI">
            <el-input v-model="searchParams.imei" clearable size="mini" placeholder="请输入设备编号" @change="onSearch" />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="设备编号">
            <el-input v-model="searchParams.code" clearable size="mini" placeholder="请输入设备编号" @change="onSearch" />
          </el-form-item>
        </el-col>
        <business-search :span="span" :dataObj="searchParams" @change="onSearch" />
        <el-col :span="span">
          <el-form-item label="使用状态">
            <el-select v-model="searchParams.type" style="width:100%" clearable placeholder="请选择设备类型" size="small" @change="onSearch">
              <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="right-btns">
          <el-button icon="el-icon-search" class="search-box-btn" size="mini" type="primary" @click="onSearch">查询</el-button>
          <el-button icon="el-icon-circle-plus-outline" class="search-box-btn" size="mini" type="primary" @click="onAdd">添加SIM卡</el-button>
        </el-col>
      </el-row>
    </el-form>
    <div v-loading="loading">
      <el-table :data="dataList" style="width: 100%" :height="tableHeight"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column type="index" label="序号" width="50" align="center">
          <template slot-scope="scope">
            {{ (scope.$index + 1) + (pageData.current - 1) * pageData.size }}
          </template>
        </el-table-column>
        <el-table-column prop="iccId" label="ICCID" align="center" />
        <el-table-column prop="imei" label="IMEI" align="center" />
        <el-table-column prop="code" label="设备编号" align="center" />
        <el-table-column prop="activationTime" label="激活时间" align="center" >
          <template slot-scope="scope">
            {{ scope.row.activationTime.slice(0, scope.row.activationTime.length - 9) }}
          </template>
        </el-table-column>
        <el-table-column prop="expireTime" label="过期时间" align="center" >
          <template slot-scope="scope">
            {{ scope.row.expireTime.slice(0, scope.row.expireTime.length - 9) }}
          </template>
        </el-table-column>
        <el-table-column prop="annualFee" label="年费" align="center" />
        <el-table-column prop="type" label="使用状态" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ typeList[scope.row.type].label }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="adminMobile" label="操作" :width="100" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" style="margin-left: 10px;" size="mini" icon="el-icon-edit" @click="onEdit(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
        :page-sizes="[20, 50, 100]" @size-change="onSizeChange"
        :page-size="pageData.size" :total="pageData.total" :current-page="pageData.current" @current-change="pageChange" />
    </div>
    <el-dialog :title="dialogTitle" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" v-if="dialogVisible.sim" :visible="dialogVisible.sim" width="600px"
      @close="onCloseDialog('sim')"
    >
      <edit ref="sim" :data="rowData" :visible="dialogVisible.sim" @close-edit-modal="onCloseDialog('sim', true)" />
    </el-dialog>
  </div>
</template>
<script>
import simApi from "@/api/sim";

import edit from "./edit";
import businessSearch from "@/components/business/search";

export default {
  components: {
    businessSearch,
    edit
  },
  data () {
    return {
      span: 4,
      loading: false,
      dialogVisible: {
        sim: false
      },
      searchParams: {
        code: "",
        iccid: "",
        imei: "",
        tenantId: "",
        businessId: "",
        type: "",
      },
      dialogTitle: "",
      rowData: null,
      typeList: $statics.SIM_USE_TYPE_LIST,
      pageData: {
        page: 1,
        size: 20,
        total: 0
      },
      dataList: []
    }
  },
  created () {
    console.log("project-sim");
    this.onSearch();
  },
  mounted () {
    this.tableHeight = document.body.scrollHeight - 255;
  },
  methods: {
    onSearch () {
      this.pageData.current = 1;
      this.getDatas();
    },
    pageChange(e) {
      this.pageData.current = e;
      this.getDatas();
    },
    onSizeChange (e) {
      this.pageData.size = e;
      this.pageData.current = 1;
      this.getDatas();
    },
    async getDatas () {
      try {
        this.loading = true;
        const parms = Object.assign({
          page: this.pageData.current,
          size: this.pageData.size
        }, this.searchParams);
        const res = await simApi.list(parms);
        this.dataList = res.data;
        this.pageData = res.paging;
      } finally {
        this.loading = false;
      }
    },
    onAdd () {
      this.dialogTitle = "添加新SIM卡";
      this.dialogVisible.sim = true;
    },
    onEdit (data) {
      this.dialogTitle = "修改SIM卡信息";
      this.dialogVisible.sim = true;
      this.rowData = data;
    },
    async onDel (row) {
      try {
        this.loading = true;
        await simApi.del(row.id);
        await this.getDatas();
      }finally {
        this.loading = false;
      }
    },
    async onCloseDialog (refName, ignore) {;
      // 通过 emit 发送的关闭请求一律通过
      // 如果点击 close 图标则一律直接关闭
      if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
        // 设置 visable 后,会重新触发 close 所以需要先 remove edit
        delete this.$refs.edit;
        this.dialogVisible[refName] = false;
        await this.getDatas();
      }
    }
  }
}
</script>