<template>
  <div class="tabs-data-box tabs-data-box2">
    <el-form label-width="80px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="固件类别" prop="description">
            <el-select size="small" clearable v-model="searchParams.type" placeholder="请选择固件类别" @change="onSearch">
              <el-option v-for="node in typeList" in :key="node.value" :label="node.label" :value="node.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="right-btns">
          <el-button size="small" icon="el-icon-upload" type="primary" @click="onOpenDialog">{{`上传固件`}}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table v-loading="loading" :data="list" style="width: 100%" :height="tableHeight"
      :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
      <el-table-column type="index" label="序号" width="50" align="center">
        <template slot-scope="scope">
          {{ (scope.$index + 1) + (pageData.current - 1) * pageData.size }}
        </template>
      </el-table-column>
      <el-table-column prop="edition" label="固件类别" width="100" align="center">
        <template slot-scope="scope">
          {{typeMap[scope.row.type].label}}
        </template>
      </el-table-column>
      <el-table-column prop="edition" label="固件版本" width="100"></el-table-column>
      <el-table-column prop="deviceName" label="文件名" width="200"></el-table-column>
      <el-table-column prop="size" label="文件大小" width="100">
        <template slot-scope="scope">
          {{ scope.row.size }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="deviceCnt" label="使用设备数量"></el-table-column> -->
      <el-table-column prop="description" label="固件说明"></el-table-column>
      <el-table-column prop="createTime" label="上传时间" width="160">
        <template slot-scope="scope">
          {{ scope.row.createTime | formatTime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center" v-if="!utils.userReadOnly(user)">
        <template slot-scope="scope">
          <div class="text-center">
            <el-button type="primary" size="mini" icon="el-icon-edit" @click="onEditFirmware(scope.row)" style="margin-right:10px;" />
            <el-popconfirm
              icon="el-icon-info"
              icon-color="#FF0000"
              confirm-button-type="danger"
              title="删除操作不可恢复，您确认要删除么？"
              @confirm="onDelFirmware(scope.row)"
            >
              <el-button type="danger" slot="reference" size="mini" icon="el-icon-delete" />
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
      :page-sizes="[20, 50, 100]" @size-change="onSizeChange"
      :page-size="pageData.size" :total="pageData.total" :current-page="pageData.current" @current-change="pageChange" />
    <el-dialog :title="name" :close-on-press-escape="false" show-close
      :close-on-click-modal="true" v-if="dialogVisible" :visible="dialogVisible" width="600px"
      @close="onCloseDialog()"
    >
      <edit ref="edit" :data="data" :is-add="isAdd" :type="type" :title="name" :visible="dialogVisible" @close-edit-modal="onCloseDialog(true)" />
    </el-dialog>
  </div>
</template>

<script>
import { getFirmwareList, deleteFirmware } from "@/api/firmware";
import edit from "./edit";
import { mapGetters } from 'vuex';

const searchParams = {
  type: ""
};

export default {
  components: {
    edit
  },
  computed: {
    ...mapGetters(['user']),
  },
  props: {
    type: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      utils: $utils,
      isAdd: false,
      typeList: [].concat($statics.FIRMWARE_TYPE_LIST).slice(0, 7),
      typeMap: $statics.FIRMWARE_TYPE_MAP,
      searchParams: {},
      list: [],
      pageData: {
        current: 1,
        size: 20,
        total: 0
      },
      dialogVisible: false,
      name: "",
      data: null
    };
  },
  created () {
    this.searchParams = searchParams;
    this.getDatas();
  },
  mounted () {
    this.tableHeight = document.body.scrollHeight - 205;
  },
  methods: {
    onSizeChange (e) {
      this.pageData.size = e;
      this.pageData.current = 1;
      this.getDatas();
    },
    pageChange(e) {
      this.pageData.current = e;
      this.getDatas();
    },
    onSearch () {
      this.pageData.current = 1;
      this.getDatas();
    },
    async getDatas () {
      this.loading = true;

      const params = {
        page: this.pageData.current,
        size: this.pageData.size,
        type: searchParams.type
      };

      const res = await getFirmwareList(params);

      this.list = res.data;
      this.pageData = res.paging || {
        current: 12, size: 10, total: 200
      };

      this.loading = false;
    },
    onOpenDialog () {
      this.name = `上传${this.title}固件`;
      this.dialogVisible = true;
      this.data = null;
      this.isAdd = true;
    },
    onEditFirmware (row) {
      this.name = "编辑固件信息";
      this.dialogVisible = true;
      this.data = row;
      this.isAdd = false;
    },
    async onDelFirmware (row) {
      try {
        this.loading = true;
        await deleteFirmware(row.id);
        this.getDatas();
      } finally {
        this.loading = false;
      }
    },
    async onCloseDialog (ignore) {;
      // 通过 emit 发送的关闭请求一律通过
      // 如果点击 close 图标则一律直接关闭
      if (ignore || !this.$refs.edit || await this.$refs.edit.onClosed()) {
        // 设置 visable 后,会重新触发 close 所以需要先 remove edit
        delete this.$refs.edit;
        this.dialogVisible = false;
        await this.getDatas();
      }
    }
  }
}
</script>