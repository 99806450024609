<template>
  <div>
    <div v-if="utils.checkNumberIsNotEmpty(status)">
      <div class="work-mode-box">
        <div>
          <!-- 【废弃】[20231225]充放电规则 0：静置，2：放电，1：充电 -->
          <!-- [20140105]充放电规则 主控： 0：静置，1：充电，2：放电 -->
          <div v-if="projectType === 0">
            <div v-if="status === 0" class="stop-bg"></div>
            <div v-else-if="status === 1" class="charge-bg animation"></div>
            <div v-else-if="status === 2" class="discharge-bg animation"></div>
  
          </div>
          <!-- [20140105]充放电规则 总控： 0：静置，1：放电，2：充电 -->
          <div v-if="projectType === 1 || projectType === 6">
            <div v-if="status === 0" class="stop-bg"></div>
            <div v-else-if="status === 1" class="discharge-bg animation"></div>
            <div v-else-if="status === 2" class="charge-bg animation"></div>
          </div>
        </div>
        <div>{{ modeList[projectType][status] }}</div>
      </div>
    </div>
    <div v-else>无数据</div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    deviceInfo: undefined,
    object: undefined
  },
  data () {
    return {
      utils: $utils,
      status: null,
      projectType: 0,
      modeList: [
        [ "静置中", "充电中", "放电中" ],
        [ "静置中", "放电中", "充电中" ]
      ]
    }
  },
  watch: {
    "object": function (v1, v2) {
      this.status = v1[this.name];
    },
    "deviceInfo": function (v1, v2) {
      this.init();
    }
  },
  methods: {
    init () {
      this.projectType = this.deviceInfo.projectType;
      if (this.projectType === 6) {
        this.projectType = 1;
      }
      if (this.object) {
        this.status = Number(this.object[this.name]);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.charge-bg {
  background: #ffff00;
}

.discharge-bg {
  background: #00ff00;
}

.stop-bg {
  background: #CCCCCC;
}

.work-mode-box {
  >div {
    display: inline-block;
    vertical-align: middle;
    >div>div:first-child {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      position: absolute;
      top: 1px;
    }

    .animation {
      animation-name: roundScale;
      animation-duration: 1.3s;
      animation-iteration-count: infinite;
      top: 0px!important;

      @keyframes roundScale {
        50% {
          transform: scale(1.3);
          -webkit-transform: scale(1.3);
          -moz-transform: scale(1.3);
          -o-transform: scale(1.3);
          -ms-transform: scale(1.3);
        }
      }
    }
  }
  >div:first-child {
    height: 18px;
    width: 18px;
    position: relative;
    margin-right: 5px;
  }
}
</style>