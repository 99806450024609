import request from '@/utils/request';

// 获取固件列表
export function getFirmwareList (params) {
  const rand = Date.now();
  return request.get(`devices/wrap/list?${rand}`, { params });
}

// 更新固件信息
export function updateFirmware (data) {
  const rand = Date.now();
  return request.post(`devices/wrap/update?${rand}`, data);
}

// 删除固件
export function deleteFirmware (id) {
  const rand = Date.now();
  return request.get(`devices/wrap/delete/${id}?${rand}`);
}