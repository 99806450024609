var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "60vh",
            overflow: "auto",
            "padding-right": "20px",
            "margin-bottom": "20px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "生产日期", prop: "hcCreateTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      readonly: !_vm.canEdit,
                      size: "mini",
                      type: "date",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.form.hcCreateTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcCreateTime", $$v)
                      },
                      expression: "form.hcCreateTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "项目编号", prop: "hcNo" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcNo", $$v)
                      },
                      expression: "form.hcNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "项目名称", prop: "hcNo" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcProject,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcProject", $$v)
                      },
                      expression: "form.hcProject",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "hcNo" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcCompnay,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcCompnay", $$v)
                      },
                      expression: "form.hcCompnay",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "产品名称", prop: "hcProduct" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcProduct,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcProduct", $$v)
                      },
                      expression: "form.hcProduct",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "产品型号", prop: "hcType" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcType", $$v)
                      },
                      expression: "form.hcType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "生产场地", prop: "hcCreatePlace" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcCreatePlace,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcCreatePlace", $$v)
                      },
                      expression: "form.hcCreatePlace",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "来源方式", prop: "hcSource" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcSource,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcSource", $$v)
                      },
                      expression: "form.hcSource",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "外购单位", prop: "hcOutsourcing" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcOutsourcing,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcOutsourcing", $$v)
                      },
                      expression: "form.hcOutsourcing",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "安装人员", prop: "hcInstallers" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcInstallers,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcInstallers", $$v)
                      },
                      expression: "form.hcInstallers",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "测试人员", prop: "hcTesters" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcTesters,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcTesters", $$v)
                      },
                      expression: "form.hcTesters",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "操作员", prop: "hcOperators" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcOperators,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcOperators", $$v)
                      },
                      expression: "form.hcOperators",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "PCBA编码", prop: "hcPcba" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcPcba,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcPcba", $$v)
                      },
                      expression: "form.hcPcba",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "成品码", prop: "hcFinish" } },
                [
                  _c("el-input", {
                    attrs: { readonly: !_vm.canEdit, size: "mini" },
                    model: {
                      value: _vm.form.hcFinish,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hcFinish", $$v)
                      },
                      expression: "form.hcFinish",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-btns" },
        [
          !_vm.canEdit
            ? _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.onShowQrCode },
                },
                [_vm._v(_vm._s(_vm.evtName) + "二维码")]
              )
            : _vm._e(),
          _vm.canEdit
            ? _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("提交数据")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.dialogVisible.qrcode
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "二维码",
                "destroy-on-close": "",
                "close-on-press-escape": false,
                "show-close": "",
                "append-to-body": "",
                "close-on-click-modal": true,
                visible: _vm.dialogVisible.qrcode,
                width: "640px",
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseDialog("qrcode", false)
                },
              },
            },
            [
              _c("qrcode", {
                ref: "qrcode",
                attrs: { data: _vm.data, mode: "dialog" },
                on: {
                  "close-edit-modal": function ($event) {
                    return _vm.onCloseDialog("qrcode")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }