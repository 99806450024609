var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tabs-data-box tabs-data-box2",
      on: { click: _vm.hideCheckBoxGroup },
    },
    [
      _c(
        "el-form",
        {
          staticStyle: { "padding-left": "20px" },
          attrs: { "label-width": "80" },
        },
        [
          _c(
            "el-row",
            { attrs: { span: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "查询时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          size: "mini",
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "default-time": _vm.defaultTime,
                          "picker-options": _vm.pickerOptions,
                        },
                        on: { change: _vm.getDatas },
                        model: {
                          value: _vm.timeRange,
                          callback: function ($$v) {
                            _vm.timeRange = $$v
                          },
                          expression: "timeRange",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "只看最大最小值" } },
                    [
                      _c("el-checkbox", {
                        on: { change: _vm.onSearch },
                        model: {
                          value: _vm.lookMaxMin,
                          callback: function ($$v) {
                            _vm.lookMaxMin = $$v
                          },
                          expression: "lookMaxMin",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选取设备" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "calc(100% - 90px)" },
                          attrs: {
                            size: "mini",
                            "collapse-tags": "",
                            multiple: "",
                            placeholder: "请选择设备",
                            filterable: "",
                            clearable: "",
                          },
                          on: { change: _vm.onSearch },
                          model: {
                            value: _vm.selectedCodes,
                            callback: function ($$v) {
                              _vm.selectedCodes = $$v
                            },
                            expression: "selectedCodes",
                          },
                        },
                        _vm._l(_vm.deviceList, function (device) {
                          return _c("el-option", {
                            key: device.code,
                            attrs: {
                              label: `${device.information}(${device.code})`,
                              value: device.code,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 6 } }, [
                _c(
                  "div",
                  { staticClass: "right-btns" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "4px",
                          display: "inline-block",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        !_vm.useImdependenceItem
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: _vm.loading,
                                  icon: _vm.checkBoxIcon,
                                  size: "mini",
                                },
                                on: { click: _vm.showHideCheckBoxGroup },
                              },
                              [_vm._v("选择要显示的项目")]
                            )
                          : _vm._e(),
                        _vm.isShowCheckBoxGroup
                          ? _c(
                              "div",
                              { staticClass: "columns-select-box" },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    on: {
                                      change: _vm.handleCheckedColumnsChange,
                                    },
                                    model: {
                                      value: _vm.checkedColumns,
                                      callback: function ($$v) {
                                        _vm.checkedColumns = $$v
                                      },
                                      expression: "checkedColumns",
                                    },
                                  },
                                  _vm._l(_vm.columns, function (column, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "check-box-item-box",
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: column.value } },
                                          [_vm._v(_vm._s(column.label))]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                                _c(
                                  "div",
                                  { staticClass: "checkbox-btns-box" },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              indeterminate:
                                                _vm.isIndeterminate,
                                            },
                                            on: {
                                              change: _vm.handleCheckAllChange,
                                            },
                                            model: {
                                              value: _vm.checkAll,
                                              callback: function ($$v) {
                                                _vm.checkAll = $$v
                                              },
                                              expression: "checkAll",
                                            },
                                          },
                                          [_vm._v("全选")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "right-btns" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { size: "small" },
                                            on: {
                                              click: _vm.toDefaultCheckBox,
                                            },
                                          },
                                          [_vm._v("默认值")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { size: "small" },
                                            on: {
                                              click: _vm.hideCheckBoxGroup,
                                            },
                                          },
                                          [_vm._v("取消")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "small",
                                            },
                                            on: {
                                              click: _vm.onSelectShowColumns,
                                            },
                                          },
                                          [_vm._v("确定")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "search-box-btn",
                        attrs: {
                          icon: "el-icon-search",
                          disabled: _vm.loading,
                          type: "primary",
                          size: "mini",
                        },
                        on: { click: _vm.onSearch },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "data-table",
            staticStyle: { "margin-left": "10px" },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    ref: "table",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.list,
                      height: _vm.height ? _vm.height : undefined,
                      "header-cell-style": {
                        background: "#F7F7F7",
                        color: "#2d2d2d",
                        "text-align": "center",
                      },
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: "序号",
                        width: "50",
                        align: "center",
                        fixed: "left",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    scope.row.type === 1 ? "error-color" : "",
                                },
                                [_vm._v(_vm._s(scope.$index + 1))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "上报时间",
                        prop: "createTime",
                        width: "160",
                        align: "center",
                        fixed: "left",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    scope.row.type === 1 ? "error-color" : "",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.updateTime ||
                                        scope.row.createTime
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "设备编号",
                        prop: "code",
                        width: "80",
                        align: "center",
                        fixed: "left",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    scope.row.type === 1 ? "error-color" : "",
                                },
                                [_vm._v(_vm._s(scope.row.code))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm.checkedColumnsSet.has("onOffState")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "开关机状态",
                            "min-width": "220",
                            prop: "onOffState",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.onOffState
                                    ) && scope.row.onOffState !== "0000"
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.runStatusMap[
                                                  scope.row.onOffState
                                                ].label
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1711866615
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemState")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "EMS系统状态",
                            "min-width": "220",
                            prop: "systemState",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkNumberIsNotEmpty(
                                      scope.row.systemState
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.systemStatusMap[
                                                  scope.row.systemState
                                                ].label
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1795180053
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("temperature")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统温度",
                            "min-width": "150",
                            prop: "temperature",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.temperature
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                (
                                                  Number(
                                                    scope.row.temperature
                                                  ) / 10
                                                ).toFixed(2)
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2386844957
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("humidity")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统湿度",
                            "min-width": "150",
                            prop: "humidity",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.humidity
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                (
                                                  Number(scope.row.humidity) /
                                                  10
                                                ).toFixed(2)
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1029926365
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("accumulationDischarge")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.accumulationDischarge,
                                b.accumulationDischarge
                              ),
                            label: "累计放电电量(EMS)(KWH)",
                            "min-width": "220",
                            prop: "accumulationDischarge",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.lookMaxMin
                                      ? _c("span", [
                                          _vm.utils.checkObjectIsNotEmpty(
                                            scope.row.accumulationDischarge
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "normal-color",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row
                                                          .accumulationDischarge
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "ban-color",
                                                },
                                                [_vm._v("无数据")]
                                              ),
                                        ])
                                      : _c("span", [
                                          _vm.utils.checkObjectIsNotEmpty(
                                            scope.row
                                              .accumulationDischargeEnergy
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "normal-color",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        Number(
                                                          scope.row
                                                            .accumulationDischargeEnergy
                                                        ).toFixed(2)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "ban-color",
                                                },
                                                [_vm._v("无数据")]
                                              ),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4158913143
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("accumulationHarge")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.accumulationHarge,
                                b.accumulationHarge
                              ),
                            label: "累计充电电量(EMS)(KWH)",
                            "min-width": "220",
                            prop: "accumulationHarge",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.accumulationHarge
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row
                                                        .accumulationHarge
                                                    : Number(
                                                        scope.row
                                                          .accumulationHarge
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1157883082
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("airConditioningSum")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "空调数量",
                            "min-width": "120",
                            prop: "airConditioningSum",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.airConditioningSum
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.airConditioningSum
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2159255008
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("airConditioningStatus")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "空调状态",
                            "min-width": "300",
                            prop: "airConditioningStatus",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.lookMaxMin
                                      ? _c("span", [
                                          _vm.utils.checkObjectIsNotEmpty(
                                            scope.row.airConditioningStatus
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "normal-color",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row
                                                          .airConditioningStatus
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "ban-color",
                                                },
                                                [_vm._v("无数据")]
                                              ),
                                        ])
                                      : _c("span", [
                                          _vm.utils.checkObjectIsNotEmpty(
                                            scope.row.airconStatusArr
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "normal-color",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row.airconStatusArr.join()
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "ban-color",
                                                },
                                                [_vm._v("无数据")]
                                              ),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            378898632
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("alternatingCurrentActivePower")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.alternatingCurrentActivePower,
                                b.alternatingCurrentActivePower
                              ),
                            label: "交流有功功率(EMS)(KW)",
                            "min-width": "220",
                            prop: "alternatingCurrentActivePower",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.alternatingCurrentActivePower
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                (
                                                  Number(
                                                    scope.row
                                                      .alternatingCurrentActivePower
                                                  ) / 10
                                                ).toFixed(2)
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3295072125
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("alternatingCurrentReactivePower")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.alternatingCurrentReactivePower,
                                b.alternatingCurrentReactivePower
                              ),
                            label: "交流无功功率(EMS)(KW)",
                            "min-width": "220",
                            prop: "alternatingCurrentReactivePower",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.alternatingCurrentReactivePower
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                Number(
                                                  scope.row
                                                    .alternatingCurrentReactivePower
                                                ).toFixed(2)
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2295128690
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("bmsHeartBeat")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(a.bmsHeartBeat, b.bmsHeartBeat),
                            label: "BMS心跳",
                            "min-width": "120",
                            prop: "bmsHeartBeat",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.bmsHeartBeat
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.bmsHeartBeat)
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            998775456
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("dailyChargingEnergy")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.dailyChargingEnergy,
                                b.dailyChargingEnergy
                              ),
                            label: "日充电量(EMS)(KWH)",
                            "min-width": "200",
                            prop: "dailyChargingEnergy",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.dailyChargingEnergy
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                Number(
                                                  scope.row.dailyChargingEnergy
                                                ).toFixed(2)
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1180500722
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("dailyDischargingEnergy")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.dailyDischargingEnergy,
                                b.dailyDischargingEnergy
                              ),
                            label: "日放电量(EMS)(KWH)",
                            "min-width": "200",
                            prop: "dailyDischargingEnergy",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.dailyDischargingEnergy
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                Number(
                                                  scope.row
                                                    .dailyDischargingEnergy
                                                ).toFixed(2)
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2569859698
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("dailyEnergySaving")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.dailyEnergySaving,
                                b.dailyEnergySaving
                              ),
                            label: "日节能费用(EMS)(元)",
                            "min-width": "220",
                            prop: "dailyEnergySaving",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.dailyEnergySaving
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                Number(
                                                  scope.row.dailyEnergySaving
                                                ).toFixed(2)
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3257787506
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("fireProtectionStatus")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "消防状态",
                            "min-width": "120",
                            prop: "fireProtectionStatus",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.fireProtectionStatus
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.fireProtectionStatus
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2420646368
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("maxChargePower")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.maxChargePower,
                                b.maxChargePower
                              ),
                            label: "最大允许充电功率(KW)",
                            "min-width": "190",
                            prop: "maxChargePower",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.maxChargePower
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                Number(
                                                  scope.row.maxChargePower
                                                ).toFixed(2)
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3236816786
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("maxDischargePower")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.maxDischargePower,
                                b.maxDischargePower
                              ),
                            label: "最大允许放电功率(KW)",
                            "min-width": "190",
                            prop: "maxDischargePower",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.maxDischargePower
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                Number(
                                                  scope.row.maxDischargePower
                                                ).toFixed(2)
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            442285490
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("numberOfRacksInService")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "当前在网簇数",
                            "min-width": "140",
                            prop: "numberOfRacksInService",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.numberOfRacksInService
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.numberOfRacksInService
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            954015584
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("numberOfTotalRacks")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "堆内总簇数",
                            "min-width": "120",
                            prop: "numberOfTotalRacks",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.numberOfTotalRacks
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.numberOfTotalRacks
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1445948000
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("rack")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "RACK 在线状态",
                            "min-width": "300",
                            prop: "rack",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.lookMaxMin
                                      ? _c("span", [
                                          _vm.utils.checkObjectIsNotEmpty(
                                            scope.row.rack
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "normal-color",
                                                },
                                                [_vm._v(_vm._s(scope.row.rack))]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "ban-color",
                                                },
                                                [_vm._v("无数据")]
                                              ),
                                        ])
                                      : _c("span", [
                                          _vm.utils.checkObjectIsNotEmpty(
                                            scope.row.rackOnlineStatusArray
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "normal-color",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.rackOnlineStatusArray.join()
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "ban-color",
                                                },
                                                [_vm._v("无数据")]
                                              ),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3996876904
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("rackFaultSummary")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统一级报警汇总",
                            "min-width": "400",
                            prop: "rackFaultSummary",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.rackFaultSummary
                                    )
                                      ? _c("span", [
                                          scope.row.rackFaultSummary === "正常"
                                            ? _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    class:
                                                      scope.row.type === 1
                                                        ? "error-color"
                                                        : "normal-color",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row
                                                          .rackFaultSummary
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "warning-color",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row
                                                          .rackFaultSummary
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                        ])
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1779579995
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("rackWarningSummary")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统一级报警汇总",
                            "min-width": "400",
                            prop: "rackWarningSummary",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.rackWarningSummary
                                    )
                                      ? _c("span", [
                                          scope.row.rackWarningSummary ===
                                          "正常"
                                            ? _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    class:
                                                      scope.row.type === 1
                                                        ? "error-color"
                                                        : "normal-color",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row
                                                          .rackWarningSummary
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "warning-color",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row
                                                          .rackWarningSummary
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                        ])
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1673512155
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("rackAlarmSummary")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统二级报警汇总",
                            "min-width": "400",
                            prop: "rackAlarmSummary",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.rackAlarmSummary
                                    )
                                      ? _c("span", [
                                          scope.row.rackAlarmSummary === "正常"
                                            ? _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    class:
                                                      scope.row.type === 1
                                                        ? "error-color"
                                                        : "normal-color",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row
                                                          .rackAlarmSummary
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "alarm-color",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row
                                                          .rackAlarmSummary
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                        ])
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1316448706
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("rackCriticalAlarmSummary")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统三级报警汇总",
                            "min-width": "400",
                            prop: "rackCriticalAlarmSummary",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.rackCriticalAlarmSummary
                                    )
                                      ? _c("span", [
                                          _vm.lookMaxMin
                                            ? _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    class:
                                                      scope.row.type === 1
                                                        ? "error-color"
                                                        : "normal-color",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row
                                                          .rackCriticalAlarmSummary
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _c("span", [
                                                scope.row
                                                  .rackCriticalAlarmSummary ===
                                                "正常"
                                                  ? _c("span", [
                                                      _c(
                                                        "span",
                                                        {
                                                          class:
                                                            scope.row.type === 1
                                                              ? "error-color"
                                                              : "normal-color",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .rackCriticalAlarmSummary
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "critical-alarm-color",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .rackCriticalAlarmSummary
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                              ]),
                                        ])
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4161270412
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemCharge")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统充放电状态",
                            "min-width": "170",
                            prop: "systemCharge",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemCharge
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row.systemCharge
                                                    : _vm.systemChargeList[
                                                        scope.row.systemCharge
                                                      ]
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            757268696
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemEnableChargeEnergy")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.systemEnableChargeEnergy,
                                b.systemEnableChargeEnergy
                              ),
                            label: "系统可充电量(KWH)",
                            "min-width": "220",
                            prop: "systemEnableChargeEnergy",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemEnableChargeEnergy
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row
                                                        .systemEnableChargeEnergy
                                                    : Number(
                                                        scope.row
                                                          .systemEnableChargeEnergy
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1748492882
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemEnableDiscargeEnergy")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.systemEnableDiscargeEnergy,
                                b.systemEnableDiscargeEnergy
                              ),
                            label: "系统可放电量(KWH)",
                            "min-width": "220",
                            prop: "systemEnableDiscargeEnergy",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemEnableDiscargeEnergy
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row
                                                        .systemEnableDiscargeEnergy
                                                    : Number(
                                                        scope.row
                                                          .systemEnableDiscargeEnergy
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            653803396
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemEnableMaxChargeCurrent")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.systemEnableMaxChargeCurrent,
                                b.systemEnableMaxChargeCurrent
                              ),
                            label: "系统允许最大充电电流(A)",
                            "min-width": "250",
                            prop: "systemEnableMaxChargeCurrent",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemEnableMaxChargeCurrent
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row
                                                        .systemEnableMaxChargeCurrent
                                                    : Number(
                                                        scope.row
                                                          .systemEnableMaxChargeCurrent
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3424992077
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemEnableMaxDischargeCurrent")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.systemEnableMaxDischargeCurrent,
                                b.systemEnableMaxDischargeCurrent
                              ),
                            label: "系统允许最大放电电流(A)",
                            "min-width": "250",
                            prop: "systemEnableMaxDischargeCurrent",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemEnableMaxDischargeCurrent
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row
                                                        .systemEnableMaxDischargeCurrent
                                                    : Number(
                                                        scope.row
                                                          .systemEnableMaxDischargeCurrent
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1471427123
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemInsulation")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.systemInsulation,
                                b.systemInsulation
                              ),
                            label: "系统绝缘值(KΩ)",
                            "min-width": "160",
                            prop: "systemInsulation",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.lookMaxMin
                                      ? _c("span", [
                                          _vm.utils.checkObjectIsNotEmpty(
                                            scope.row.systemLnsulation
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "normal-color",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row
                                                          .systemLnsulation
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "ban-color",
                                                },
                                                [_vm._v("无数据")]
                                              ),
                                        ])
                                      : _c("span", [
                                          _vm.utils.checkObjectIsNotEmpty(
                                            scope.row.systemInsulation
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "normal-color",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        Number(
                                                          scope.row
                                                            .systemInsulation
                                                        ).toFixed(2)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  class:
                                                    scope.row.type === 1
                                                      ? "error-color"
                                                      : "ban-color",
                                                },
                                                [_vm._v("无数据")]
                                              ),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4166933207
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMaxCellTemperature")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.systemMaxCellTemperature,
                                b.systemMaxCellTemperature
                              ),
                            label: "系统最高电池温度",
                            "min-width": "170",
                            prop: "systemMaxCellTemperature",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMaxCellTemperature
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row
                                                        .systemMaxCellTemperature
                                                    : Number(
                                                        scope.row
                                                          .systemMaxCellTemperature
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3287839413
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMaxCellVoltage")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.systemMaxCellVoltage,
                                b.systemMaxCellVoltage
                              ),
                            label: "系统最高电池电压(℃)",
                            "min-width": "190",
                            prop: "systemMaxCellVoltage",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMaxCellVoltage
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row
                                                        .systemMaxCellVoltage
                                                    : Number(
                                                        scope.row
                                                          .systemMaxCellVoltage
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3808146971
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMaxTempCellId")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统最高温度电池所在位置",
                            "min-width": "220",
                            prop: "systemMaxTempCellId",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMaxTempCellId
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.systemMaxTempCellId
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1769013248
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMaxTempCellRackId")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统最高温度电池所在Rack号",
                            "min-width": "240",
                            prop: "systemMaxTempCellRackId",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMaxTempCellRackId
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row
                                                  .systemMaxTempCellRackId
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            957743648
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMaxTempCellSlaveId")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统最高温度电池所在组",
                            "min-width": "220",
                            prop: "systemMaxTempCellSlaveId",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMaxTempCellSlaveId
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row
                                                  .systemMaxTempCellSlaveId
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1680583712
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMaxVolCellId")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统最高电压电池所在位置",
                            "min-width": "220",
                            prop: "systemMaxVolCellId",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMaxVolCellId
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.systemMaxVolCellId
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1069132832
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMaxVolCellRackId")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统最高电压电池所在Rack号",
                            "min-width": "240",
                            prop: "systemMaxVolCellRackId",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMaxVolCellRackId
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.systemMaxVolCellRackId
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3226440608
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMaxVolCellSlaveId")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统最高电压电池所在组",
                            "min-width": "220",
                            prop: "systemMaxVolCellSlaveId",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMaxVolCellSlaveId
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row
                                                  .systemMaxVolCellSlaveId
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3544402112
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMinCellTemperature")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.systemMinCellTemperature,
                                b.systemMinCellTemperature
                              ),
                            label: "系统最低电池温度(℃)",
                            "min-width": "190",
                            prop: "systemMinCellTemperature",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMinCellTemperature
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row
                                                        .systemMinCellTemperature
                                                    : Number(
                                                        scope.row
                                                          .systemMinCellTemperature
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3564449899
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMinCellVoltage")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.systemMinCellVoltage,
                                b.systemMinCellVoltage
                              ),
                            label: "系统最低电池电压(mV)",
                            "min-width": "190",
                            prop: "systemMinCellVoltage",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMinCellVoltage
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row
                                                        .systemMinCellVoltage
                                                    : Number(
                                                        scope.row
                                                          .systemMinCellVoltage
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            733347269
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMinTempCellId")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统最低温度电池所在位置",
                            "min-width": "220",
                            prop: "systemMinTempCellId",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMinTempCellId
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.systemMinTempCellId
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            972381376
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMinTempCellRackId")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统最低温度电池所在Rack号",
                            "min-width": "240",
                            prop: "systemMinTempCellRackId",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMinTempCellRackId
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row
                                                  .systemMinTempCellRackId
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1989076832
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMinTempCellSlaveId")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统最低温度电池所在组",
                            "min-width": "220",
                            prop: "systemMinTempCellSlaveId",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMinTempCellSlaveId
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row
                                                  .systemMinTempCellSlaveId
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3192553632
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMinVolCellId")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统最低电压电池所在位置",
                            "min-width": "220",
                            prop: "systemMinVolCellId",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMinVolCellId
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.systemMinVolCellId
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3722172448
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMinVolCellRackId")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统最低电压电池所在Rack号",
                            "min-width": "240",
                            prop: "systemMinVolCellRackId",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMinVolCellRackId
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.systemMinVolCellRackId
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2874563872
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemMinVolCellSlaveId")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "系统最低电压电池所在组",
                            "min-width": "220",
                            prop: "systemMinVolCellSlaveId",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemMinVolCellSlaveId
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row
                                                  .systemMinVolCellSlaveId
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1524926464
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemSoc")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(a.systemSoc, b.systemSoc),
                            label: "系统SOC1(%)",
                            "min-width": "140",
                            prop: "systemSoc",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemSoc
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row.systemSoc
                                                    : Number(
                                                        scope.row.systemSoc
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3906235028
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemSoh")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(a.systemSoh, b.systemSoh),
                            label: "系统SOH(%)",
                            "min-width": "140",
                            prop: "systemSoh",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemSoh
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row.systemSoh
                                                    : Number(
                                                        scope.row.systemSoh
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1544252575
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemTotalCurrent")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.systemTotalCurrent,
                                b.systemTotalCurrent
                              ),
                            label: "系统总电流(A)",
                            "min-width": "140",
                            prop: "systemTotalCurrent",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemTotalCurrent
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row
                                                        .systemTotalCurrent
                                                    : Number(
                                                        scope.row
                                                          .systemTotalCurrent
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            602417728
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("systemTotalVoltage")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.systemTotalVoltage,
                                b.systemTotalVoltage
                              ),
                            label: "系统总电压(V)",
                            "min-width": "140",
                            prop: "systemTotalVoltage",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.systemTotalVoltage
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row
                                                        .systemTotalVoltage
                                                    : Number(
                                                        scope.row
                                                          .systemTotalVoltage
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3144300523
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("totalEnergySaving")
                      ? _c("el-table-column", {
                          attrs: {
                            sortable: "",
                            "sort-method": (a, b) =>
                              _vm.sortMethod(
                                a.totalEnergySaving,
                                b.totalEnergySaving
                              ),
                            label: "总节能费用(EMS)(元)",
                            "min-width": "180",
                            prop: "totalEnergySaving",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.totalEnergySaving
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.lookMaxMin
                                                    ? scope.row
                                                        .totalEnergySaving
                                                    : Number(
                                                        scope.row
                                                          .totalEnergySaving
                                                      ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3240258826
                          ),
                        })
                      : _vm._e(),
                    _vm.checkedColumnsSet.has("waterloggingStatus")
                      ? _c("el-table-column", {
                          attrs: {
                            label: "水浸状态",
                            "min-width": "120",
                            prop: "waterloggingStatus",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.utils.checkObjectIsNotEmpty(
                                      scope.row.waterloggingStatus
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "normal-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.waterloggingStatus
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.type === 1
                                                ? "error-color"
                                                : "ban-color",
                                          },
                                          [_vm._v("无数据")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2477693024
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }