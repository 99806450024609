<template>
  <div>
    <el-col :span="span">
      <el-form-item label="公司分类">
      <el-select v-model="dataObj.tenantId" style="width:100%" clearable placeholder="请选择公司分类" size="mini" @change="onSelect">
        <el-option v-for="item in roleGroups" :key="item.name" :label="item.label" :value="item.value"></el-option>
      </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="span">
      <el-form-item label="公司名称">
      <el-select v-model="dataObj.businessId" style="width:100%" :disabled="companyDisabled" filterable clearable placeholder="请选择一个公司" size="mini" @change="onChange">
        <el-option v-for="item in companyList" :key="item.id" :label="item.companyName" :value="item.id"></el-option>
      </el-select>
      </el-form-item>
    </el-col>
  </div>
</template>
<script>
import mixin from "./mixin";

export default {
    mixins: [ mixin ]
}
</script>