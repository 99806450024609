<template>
  <div v-loading="loading">
    <div style="max-height: 60vh; overflow:auto; padding-right: 20px; margin-bottom: 20px;">
      <el-form ref="form" label-width="100px" :model="form" :rules="rules">
        <!--
  生产日期
  生产场地
  来源方式
  外购单位
  安装人员
  测试人员
  操作员
  项目编号：ER1004
  项目名称：能亿一主五从项目
  公司名称：深圳闻信科技有限公司
  产品名称：智能主控管理系统
  产品型号：BCU-Q3_V1.3
  PCBA编码：2412001700001
  成品码：2412001000001
        -->
        <el-form-item label="生产日期" prop="hcCreateTime">
          <el-date-picker
            v-model="form.hcCreateTime"
            :readonly="!canEdit"
            size="mini"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="项目编号" prop="hcNo">
          <el-input :readonly="!canEdit" size="mini" v-model="form.hcNo" />
        </el-form-item>
        <el-form-item label="项目名称" prop="hcNo">
          <el-input :readonly="!canEdit" size="mini" v-model="form.hcProject" />
        </el-form-item>
        <el-form-item label="公司名称" prop="hcNo">
          <el-input :readonly="!canEdit" size="mini" v-model="form.hcCompnay" />
        </el-form-item>
        <el-form-item label="产品名称" prop="hcProduct">
          <el-input :readonly="!canEdit" size="mini"  v-model="form.hcProduct" />
        </el-form-item>
        <el-form-item label="产品型号" prop="hcType">
          <el-input :readonly="!canEdit" size="mini" v-model="form.hcType" />
        </el-form-item>
        <el-form-item label="生产场地" prop="hcCreatePlace">
          <el-input :readonly="!canEdit" size="mini" v-model="form.hcCreatePlace" />
        </el-form-item>
        <el-form-item label="来源方式" prop="hcSource">
          <el-input :readonly="!canEdit" size="mini" v-model="form.hcSource" />
        </el-form-item>
        <el-form-item label="外购单位" prop="hcOutsourcing">
          <el-input :readonly="!canEdit" size="mini" v-model="form.hcOutsourcing" />
        </el-form-item>
        <el-form-item label="安装人员" prop="hcInstallers">
          <el-input :readonly="!canEdit" size="mini" v-model="form.hcInstallers" />
        </el-form-item>
        <el-form-item label="测试人员" prop="hcTesters">
          <el-input :readonly="!canEdit" size="mini" v-model="form.hcTesters" />
        </el-form-item>
        <el-form-item label="操作员" prop="hcOperators">
          <el-input :readonly="!canEdit" size="mini" v-model="form.hcOperators" />
        </el-form-item>
        <el-form-item label="PCBA编码" prop="hcPcba">
          <el-input :readonly="!canEdit" size="mini" v-model="form.hcPcba" />
        </el-form-item>
        <el-form-item label="成品码" prop="hcFinish">
          <el-input :readonly="!canEdit" size="mini" v-model="form.hcFinish" />
        </el-form-item>
      </el-form>
    </div>
    <div class="right-btns">
      <el-button size="mini" v-if="!canEdit" type="primary" @click="onShowQrCode">{{ evtName }}二维码</el-button>
      <el-button size="mini" v-if="canEdit" type="primary" @click="onSubmit">提交数据</el-button>
    </div>
    <el-dialog title="二维码" destroy-on-close :close-on-press-escape="false" show-close append-to-body
      :close-on-click-modal="true" v-if="dialogVisible.qrcode" :visible="dialogVisible.qrcode"
      @close="onCloseDialog('qrcode', false)"  width="640px">
      <qrcode ref="qrcode" :data="data" mode="dialog"  @close-edit-modal="onCloseDialog('qrcode')"></qrcode>
    </el-dialog>
  </div>
</template>
<script>
import qrCodeApi from "@/api/qrcode";

import qrcode from "./qrcode";

export default {
  components: {
    qrcode
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      canEdit: false,
      evtName: "",
      data: null,
      dialogVisible: {
        qrcode: false,
      },
      form: {
        hcNo: "",
        hcProject: "",
        hcCompnay: "",
        hcProduct: "",
        hcType: "",
        hcCreateTime: "",
        hcCreatePlace: "",
        hcSource: "",
        hcOutsourcing: "",
        hcInstallers: "",
        hcTesters: "",
        hcOperators: "",
        hcPcba: "",
        hcFinish: ""
      },
      rules: {
        hcCreateTime: [
          { required: true, message: '请选择生产日期', trigger: 'blur' },
        ],
        hcNo: [
          { required: true, message: '请输入项目编号', trigger: 'blur' },
        ],
        hcProject: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
        ],
        hcCompnay: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        hcProduct: [
          { required: true, message: '请输入产品名称', trigger: 'blur' },
        ],
        hcType: [
          { required: true, message: '请输入产品型号', trigger: 'blur' },
        ],
        hcCreatePlace: [
          { required: true, message: '请输入生产场地', trigger: 'blur' },
        ],
        hcSource: [
          { required: true, message: '请输入来源方式', trigger: 'blur' },
        ],
        hcOutsourcing: [
          { required: true, message: '请输入外购单位', trigger: 'blur' },
        ],
        hcInstallers: [
          { required: true, message: '请输入安装人员', trigger: 'blur' },
        ],
        hcTesters: [
          { required: true, message: '请输入测试人员', trigger: 'blur' },
        ],
        hcOperators: [
          { required: true, message: '请输入操作员', trigger: 'blur' },
        ],
        hcPcba: [
          { required: true, message: '请输入PCBA编码', trigger: 'blur' },
        ],
        hcFinish: [
          { required: true, message: '请输入成品码', trigger: 'blur' },
        ]
      }
    }
  },
  created () {
    this.canEdit = this.data === null;
    this.evtName = this.canEdit ? "生成" : "查看"
    if (!this.canEdit) {
      this.form = this.data;
    }
  },
  methods: {
    async onClosed () {
      return this.canEdit ? await $utils.closeDialogConfirm(this) : true;
    },
    async onCloseDialog (refName, ignore) {
      // 通过 emit 发送的关闭请求一律通过
      // 如果点击 close 图标则一律直接关闭
      if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
        // 设置 visable 后,会重新触发 close 所以需要先 remove edit
        delete this.$refs[refName];
        this.dialogVisible[refName] = false;
      }
    },
    onShowQrCode () {
      this.dialogVisible.qrcode = true;
    },
    onSubmit () {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return;
        }

        try {
          this.loading = true;

          const submitData = Object.assign({
            timeStamp: Date.now(),
          }, this.form);

          submitData.hcCreateTime = $utils.formatTime(submitData.hcCreateTime, "YYYY-MM-DD");

          const res = await qrCodeApi.add(submitData);

          this.$message.success("数据创建成功");

          submitData.id = res;
          this.$emit("close-edit-modal", submitData);
        } catch {
          this.$message.success("数据创建失败");
        } finally {
          this.loading = false;
        }

      });
    }
  }
}
</script>