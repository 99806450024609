<template>
  <div class="test">
    <div class="qrcode">
      <!-- 二维码 -->
        <div>项目编号：{{ HC_No }}</div>
        <canvas ref="qrcodeCanvas"></canvas>
        <div>
          <el-button type="primary" size="mini" @click="onDownload" round>下载二维码</el-button>
        </div>
    </div>
  </div>
</template>
<script>
// 引用
import QRcode from "qrcode";
export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      HC_No: "",
      timeStamp: ""
    }
  },
  created () {
    // 这里是为了兼容之前没有修改过的内容
    if (this.data.value) {
      const value = JSON.parse(this.data.value);
      this.HC_No = value.HC_No;
    } else {
      this.HC_No = this.data.hcNo;
    }
    this.timeStamp = this.data.timeStamp;
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    onClosed () {
      return true;
    },
    getDatas () {
      // 这个库传入两个参数
      // 1. canvas dom
      // 2. 字符串
      QRcode.toCanvas(
        this.$refs.qrcodeCanvas,
        (this.timeStamp).toString(),
        {
          width: 600,//自定义宽度
          color: {
            dark: "#000000", //自定义码的颜色
            light: "#FFFFFF", //自定义背景颜色
          },
        }
      );
    },
    onDownload () {
      const href = this.$refs.qrcodeCanvas.toDataURL(); // 获取canvas对应的base64编码
      const a = document.createElement('a'); // 创建a标签
      a.download = `${this.HC_No}-${this.timeStamp}`; // 设置图片名字
      a.href = href;
      a.dispatchEvent(new MouseEvent('click'));
    }
  }
};
</script>

<style lang="scss" scoped>
.qrcode {
  text-align: center;
}
</style>
