import request from '@/utils/request';

// 单体电压 列表
export function getMonomerTemperatureList (params) {
    const rand = Date.now();
    return request.get(`LogMonomerTemperature/List?${rand}`, { params });
}

export const getLogAssembleCountMaxMin = (params) => {
    const rand = Date.now();
    return request.get(`LogAssemble/count-maxMin?${rand}`, { params });
}

export default {
    getLogAssembleCountMaxMin,
    voltage: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogMonomerVoltage/List?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogMonomerVoltage/download?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        }
    },
    temperature: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogMonomerTemperature/List?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogMonomerTemperature/download?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        }
    },
    assemblevoltage: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogAssembleVoltage/pageList?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogAssembleVoltage/download?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        },
    },
    assembletemperature: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogAssembleTemperature/pageList?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogAssembleTemperature/download?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        },
    },
    alarm: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogHistoricalAlarm/List?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogHistoricalAlarm/download?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        }
    },
    gps: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogHistoricalGps/List?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogHistoricalGps/download?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        }
    },
    // 总控用
    assembleSoc: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogAssemble/pageSoc?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogAssemble/downloadSoc?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        }
    },
    // 主控用
    masterSoc: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogHistoricalSoc/List?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogHistoricalSoc/download?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        }
    },
    configs: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogParameter/List?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogParameter/download?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        }
    },
    totalconsole: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogTotalConsole/List?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogTotalConsole/download?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        }
    },
    logassemble: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogAssemble/pageList?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogAssemble/downloadTrends?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        }
    },
    logassembleData: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogAssemble/List?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogAssemble/downloadTrends?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        }
    },
    ota: {
        list: (params) => {
            const rand = Date.now();
            return request.get(`LogHistoricalOta/List?${rand}`, { params });
        },
        export: (data) => {
            const rand = Date.now();
            return request.post(`LogHistoricalOta/download?${rand}`, data, {
                responseType:'blob'  // 一定要加
            });
        }
    }
}