var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs-data-box tabs-data-box2" },
    [
      _c(
        "el-form",
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: "24" } }, [
                _c(
                  "div",
                  { staticClass: "right-btns" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.onCreateData },
                      },
                      [_vm._v("创建数据")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            height: _vm.tableHeight,
            "header-cell-style": {
              background: "#F7F7F7",
              color: "#2d2d2d",
              "text-align": "center",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "项目编号", prop: "hcNo", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "项目名称", prop: "hcProject", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "生产日期", prop: "hcCreateTime", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "220px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onDetailData(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看数据")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onShowQrCode(scope.row)
                          },
                        },
                      },
                      [_vm._v("显示二维码")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticClass: "text-right m-t-10",
        attrs: {
          background: "",
          layout: "sizes, prev, pager, next, jumper",
          "page-sizes": [20, 50, 100],
          "page-size": _vm.pageData.size,
          total: _vm.pageData.total,
          "current-page": _vm.pageData.current,
        },
        on: {
          "size-change": _vm.onSizeChange,
          "current-change": _vm.pageChange,
        },
      }),
      _vm.dialogVisible.qrcode
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "二维码",
                "destroy-on-close": "",
                "close-on-press-escape": false,
                "show-close": "",
                "close-on-click-modal": true,
                visible: _vm.dialogVisible.qrcode,
                width: "640px",
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseDialog("qrcode", false)
                },
              },
            },
            [
              _c("qrcode", {
                ref: "qrcode",
                attrs: { data: _vm.data, mode: "dialog" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.dialogVisible.edit
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                "destroy-on-close": "",
                "close-on-press-escape": false,
                "show-close": "",
                "close-on-click-modal": true,
                visible: _vm.dialogVisible.edit,
                width: "600px",
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseDialog("edit", false)
                },
              },
            },
            [
              _c("edit", {
                ref: "edit",
                attrs: { data: _vm.data, mode: "dialog" },
                on: { "close-edit-modal": _vm.onCloseEdit },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }