var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "文件描述", prop: "description" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 5, maxRows: 5 },
                  clearable: "",
                  size: "mini",
                  placeholder: "请输入配置文件的描述",
                },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-upload",
        {
          ref: "upload",
          staticStyle: { "margin-top": "5px" },
          attrs: {
            action: "",
            headers: _vm.headers,
            "on-change": _vm.handleChange,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            accept: ".ini",
            data: _vm.form,
            "file-list": _vm.fileList,
            "auto-upload": false,
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                slot: "trigger",
                icon: "el-icon-document-checked",
                size: "mini",
                type: "primary",
              },
              slot: "trigger",
            },
            [_vm._v("选取文件")]
          ),
          _c(
            "div",
            { staticClass: "m-t-10" },
            [
              _c("el-alert", {
                attrs: {
                  "show-icon": "",
                  type: "warning",
                  effect: "dark",
                  closable: false,
                  title: "只能上传 ini 配置文件",
                },
              }),
              _c("el-alert", {
                staticStyle: { "margin-top": "5px" },
                attrs: {
                  "show-icon": "",
                  type: "warning",
                  effect: "dark",
                  closable: false,
                  title:
                    "上传前，请将文件名修改为 年月日（如：20240101）的格式，避免出现重复",
                },
              }),
              _c("el-alert", {
                staticStyle: { "margin-top": "5px" },
                attrs: {
                  "show-icon": "",
                  type: "warning",
                  effect: "dark",
                  closable: false,
                  title:
                    "如果文件选择错误，请删除已上传文件后，再重新上传一次！",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "m-t-10 right-btns" },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { icon: "el-icon-upload", size: "mini", type: "primary" },
              on: { click: _vm.submitUpload },
            },
            [_vm._v("上传")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }