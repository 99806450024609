<template>
  <div v-loading="loading" class="big-data-container">
    <div class="content" :style="`height:${height}px;`">
      <el-tabs v-if="!loading" class="device-detail-tabs" tab-position="left" :style="`height:${height - 30}px;`" v-model="tabName" @tab-click="onTabClick">
        <el-tab-pane v-if="isAdmin" label="固件管理" name="firmware" style="height: 100%">
          <firmware v-if="tabName === 'firmware'" />
        </el-tab-pane>
				<el-tab-pane v-if="isAdmin" label="参数配置" name="config-log" style="height: 100%">
          <config-log v-if="tabName === 'config-log'" />
        </el-tab-pane>
				<el-tab-pane v-if="isAdmin" label="SIM卡管理" name="sim" style="height: 100%">
          <sim v-if="tabName === 'sim'" />
        </el-tab-pane>
				<el-tab-pane v-if="isAdmin" label="产品二维码" name="qrcode" style="height: 100%">
          <qrcode v-if="tabName === 'qrcode'" />
        </el-tab-pane>
        <el-tab-pane label="总控数据" name="assemble" style="height: 100%">
          <assemble-data v-if="tabName === 'assemble'" />
        </el-tab-pane>
        <el-tab-pane name="alarms" style="height: 100%">
          <template slot="label">
            <el-badge v-if="alarms.length > 0" :value="alarms.length" :max="99" class="item">
            告警数据
            </el-badge>
            <div v-else>告警数据</div>
          </template>
          <alarms v-if="tabName === 'alarms'" />
        </el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';

import ConfigLog from "./config-log"
import firmware from "./firmware";
import sim from "./sim";
import AssembleData from "./assemble-data";
import alarms from "./alarms";
import qrcode from "./qrcode";

export default {
  computed: {
    ...mapGetters(['warnings', 'user']),
  },
  components: {
    ConfigLog,
    firmware,
    sim,
    AssembleData,
    alarms,
    qrcode
  },
	data () {
		return {
			loading: false,
			height: 0,
      isAdmin: true,
      tabName: "firmware",
      alarms: []
		}
	},
  watch: {
    warnings: function(v1, v2) {
      this.alarms = v1;
    }
  },
  created () {
    if (this.warnings) {
      this.alarms = this.warnings;
    }
    this.isAdmin = this.user.tenantId === 0;
    console.log(this.isAdmin ? "admin-project" : "project");
    this.height = document.body.scrollHeight - 70;
    const tabName = this.$route.query.tab;

    if (!this.isAdmin) {
      this.tabName =  tabName || "assemble";
    } else {
      this.tabName = tabName || this.tabName;
    }
  },
  methods: {
    onTabClick () {
      this.$route.query.tab = this.tabName;
    }
  }
}
</script>